import { resolveUnref } from '@vueuse/shared';
import { compareAsc, format, formatISO, parseISO, set } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';
import {
  CommentaryType,
  DeviceTestResultCommentaryModel,
  TestResultStatus,
  TestStatus,
} from 'generated/DeviceService';
import {
  BaseModel,
  DisplayGroupOption,
  DisplayOption,
  Environment,
  Indexable,
  KeyOf,
  ModelIdType,
} from 'models/base';
import { DateFormat } from 'models/date';
import { Pagination } from 'models/pagination';
import { ParseValueOptions, Primitive } from 'models/parsing';
import { Query } from 'models/query';
import { RouteLabel, RouteLabelInfo } from 'models/routing';
import { SortDirection, SortType } from 'models/sorting';
import { TreeModel, TreeNodeModel, TreeNodeModelCache } from 'models/tree';
import { defineComponent, h, Slots } from 'vue';
import {
  LocationQueryValue,
  RouteLocationMatched,
  RouteLocationNamedRaw,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  RouteParams,
  RouteParamsRaw,
  RouteParamValueRaw,
  RouteRecordName,
  RouteRecordRaw,
  useRoute,
} from 'vue-router';

/**
 * Parses a given value and attempts to detect the correct result type
 * @param value The value to parse
 * @param options The parsing options to use for configuring how the parsing treats the given value
 * @returns A parsed value
 */
export function parseValue(value: any, options?: ParseValueOptions) {
  let parsedValue = parsePrimitiveOrArray(value);

  // EDGE CASE
  // Target property is an array, but there's a single query parameter
  // In this case, the type of query parameter will be a primitive and not an array.
  //
  // Check for the case where there's a single query parameter but the target property is an array.
  if (!Array.isArray(parsedValue) && options?.isArray) {
    parsedValue = isValueEmpty(parsedValue)
      ? []
      : ([parsedValue] as Primitive[]);
  }

  return parsedValue;
}

/**
 * Parses a potential integer value and returns a default value if the give value can't be parsed
 * @param value The value to parse as an integer
 * @returns The parsed value if it can be parsed; otherwise, the given value is returned
 */
export function parseIntSafe(value: any) {
  const parsedValue = parseInt(value);
  return isNumericType(parsedValue) ? parsedValue : value;
}

/**
 * Gets a value indicating if a given value is empty
 * @param value The value to check
 * @example
 * isValueEmpty(undefined) // true
 * isValueEmpty(null)      // true
 * isValueEmpty('')        // true
 * isValueEmpty([])        // true
 * isValueEmpty({})        // true
 * isValueEmpty(NaN)       // true
 */
export function isValueEmpty(value: any) {
  if (value !== undefined && value !== null && typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return (
    value === undefined || value === null || value === 0 || value.length === 0
  );
}

/**
 * A regular expression to get the URL parts as groups
 * @example
 * const match = urlRegex.exec('dev-app.enzeehealth.com');
 *
 * console.log(match.groups.subDomain)
 * // 'dev-app'
 *
 * console.log(match.groups.domain)
 * // 'enzeehealth.com'
 *
 * console.log(match.groups.port)
 * // ''
 */
export const urlRegex =
  // Optional subdomain (dev-app)
  // Followed by domain (enzeehealth.com)
  // Ending with optional port (NO PORT)
  /(?:(?<subDomain>[\.0-9a-zA-Z-]+)\.)*(?<domain>[\.0-9a-zA-Z-]+\.[\.0-9a-zA-Z-]+)/;

/**
 * Parses an array of strings into integers
 * @param value The value or values to parse
 * @returns The parsed values
 */
export function parseIntValuesSafe(
  value?:
    | LocationQueryValue
    | LocationQueryValue[]
    | string
    | string[]
    | null
    | undefined
) {
  if (value === undefined) {
    return [];
  }

  if (Array.isArray(value)) {
    return value?.map((itemValue) => parseIntSafe(itemValue)) || [];
  }

  const parsedValue = parseIntSafe(value as string);
  return isValueEmpty(parsedValue) ? [] : [parsedValue];
}

/**
 * Gets the current build number
 * @returns The build number
 */
export function getBuildNumber() {
  return process.env.BUILD_NUMBER || 'dev';
}

/**
 * Indicates if the application is running locally
 */
export function isLocal() {
  const hostname = location.hostname.toLocaleLowerCase();
  return hostname === 'localhost' || hostname === '127.0.0.1';
}

/**
 * Sniffs the current browser URL to determine the API URL. If the `API_URL` environment
 * variable is set, that value is always used.
 * @example
 * App URL = `https://dev-app.enzeehealth.com`
 * Returns = `https://dev-api.enzeehealth.com`
 *
 * App URL = `http://localhost:9000`
 * Returns = `http://localhost:8080`
 * @returns The API URL
 */
export function getApiUrl() {
  // Always use environment variable if set
  if (process.env.API_URL) {
    return process.env.API_URL;
  }

  const hostname = location.hostname.toLowerCase();

  // If local then we always use the port of the reverse proxy
  if (isLocal()) {
    return `${location.protocol}//${hostname}:8080`;
  }

  // A regular expression is used here to figure out the API URL
  const apiUrl = hostname.replace(urlRegex, (...args: any[]) => {
    const groups = args[args.length - 1];

    // The only difference with the current URL and API URL
    // is the API URL uses the word `api` instead of `app` in the subdomain
    //
    // App URL = `https://dev-app.enzeehealth.com`
    // Returns = `https://dev-api.enzeehealth.com`
    const subDomain = groups.subDomain ? `${groups.subDomain}.` : '';
    const apiSubDomain = subDomain.replace('app', 'api');
    const domain = groups.domain;
    // Checking the port incase we need it in the future
    const port = location.port ? `:${location.port}` : '';

    return `${location.protocol}//${apiSubDomain}${domain}${port}`;
  });

  return apiUrl;
}

/**
 * Gets the a value indicating what the current application environment is
 */
export function getEnvironment(): Environment {
  const hostname = location.hostname.toLocaleLowerCase();

  if (isLocal()) {
    return Environment.Local;
  }

  const matches = hostname.match(urlRegex);
  const subDomain = matches?.groups?.subDomain;

  if (subDomain?.startsWith('local-')) {
    return Environment.Local;
  } else if (subDomain?.startsWith('dev-')) {
    return Environment.Development;
  } else if (subDomain?.startsWith('test-')) {
    return Environment.Test;
  } else if (subDomain === 'app') {
    return Environment.Production;
  }

  // All other cases treat as production in case we change the subdomain
  return Environment.Production;
}

/**
 * Indicates if a date is valid
 * @param date The date to check
 * @returns `true` if the date is valid; otherwise, `false`
 */
export function isDateValid(date: Date | number | undefined) {
  return date instanceof Date && !isNaN(Number(date));
}

/**
 * Compares two dates in ISO format
 * @param date1 The first date to compare
 * @param date2 The second date to compare
 * @returns Zero if the dates are equal, a number greater-than zero if `date1 > date2`,
 * or a number less-than zero if `date1 < date2`
 */
export function compareDates(
  date1: string | undefined,
  date2: string | undefined
): number {
  const date1Value = parseISO(resolveUnref(date1) || '');

  if (!isDateValid(date1Value)) {
    return -1;
  }

  const date2Value = parseISO(date2 || '');

  if (!isDateValid(date2Value)) {
    return 1;
  }

  return compareAsc(date1Value, date2Value);
}

/**
 * Formats an ISO date for display
 * @param date The date to format
 * @param dateFormat The optional date form. Default is `DateForm.ShortDate`
 * @returns The formatted date
 */
export function formatDate(
  date: Date | string | undefined,
  dateFormat = DateFormat.ShortDate
) {
  // Return empty string to keep return value consistent with generated date types on models
  if (!date) {
    return '';
  }

  let parsedDate = date instanceof Date ? date : undefined;

  if (typeof date === 'string') {
    parsedDate = parseISO(date);
  }

  if (parsedDate === undefined || !isDateValid(parsedDate)) {
    return String(date);
  }

  if (dateFormat === DateFormat.UTC) {
    return formatISO(parsedDate);
  }

  return format(parsedDate, dateFormat, { locale: enUS });
}

/**
 * Formats an ISO date in a specific time zone for display
 * @param date The date to format
 * @param timeZone The optional time zone to to display the formatted date in
 * @param dateFormat The optional date form. Default is `DateForm.ShortDateTimeWithTimeZone`
 * @returns The formatted date
 */
export function formatDateInTimeZone(
  date: Date | string | undefined,
  timeZone: string,
  dateFormat = DateFormat.ShortDateTimeWithTimeZone
) {
  // Return empty string to keep return value consistent with generated date types on models
  if (!date) {
    return '';
  }

  if (dateFormat === DateFormat.UTC) {
    throw new Error(
      'The format `DateFormat.UTC` cannot be used when formatting dates in time zones'
    );
  }

  // Passing the locale allows for friendly text, like `Eastern Standard Time`
  // instead of `UTC-5:00`
  return formatInTimeZone(date, timeZone, dateFormat, { locale: enUS });
}

/**
 * Matches the time of one date to another date
 * @param valueToUpdate The value to update
 * @param dateTimeToMatch The date with the time to match
 * @returns The `valueToUpdate` date with the time matched to `dateTimeToMatch`
 */
export function matchDateTime(valueToUpdate: Date, dateTimeToMatch: Date) {
  return set(valueToUpdate, {
    hours: dateTimeToMatch.getHours(),
    minutes: dateTimeToMatch.getMinutes(),
    seconds: dateTimeToMatch.getSeconds(),
    milliseconds: dateTimeToMatch.getMilliseconds(),
  });
}

/**
 * Sets the time of a given date to midnight
 * @param value The value to change
 */
export function setDateToMidnight(value: Date) {
  return set(value, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}

const BACK_ROUTE_NAME_QUERY_KEY = 'backRouteName';
const BACK_PARAM_PREFIX = 'backRouteParam-';
const BACK_QUERY_PREFIX = 'backRouteQuery-';

/**
 * Extracts the back route parameters from a query string
 * @param query The query with the back route query
 * @example
 * const query = {
 *   'backRouteName': 'device-profile',
 *   'backRouteParam-deviceId': 10
 * };
 * const result = extractBackRouteParams(query);
 *
 * console.log(result);
 * // {
 * //   deviceId: 10
 * // }
 */
export function extractBackRouteParams(query: Indexable) {
  const routeParams: RouteParams = {};

  Object.keys(query).forEach((backKey) => {
    if (backKey.startsWith(BACK_PARAM_PREFIX)) {
      const key = backKey.substring(BACK_PARAM_PREFIX.length);
      routeParams[key] = query[backKey];
    }
  });

  return routeParams;
}

/**
 * Extracts the back route parameters from a query string
 * @param query The query with the back route query
 * @example
 * const query = {
 *   'backRouteName': 'device-profile',
 *   'backRouteParam-deviceId': 10
 *   'backRouteQuery-Status': 'Open'
 * };
 * const result = extractBackRouteQuery(query);
 *
 * console.log(result);
 * // {
 * //   Status: 'Open'
 * // }
 */
export function extractBackRouteQuery(query: Indexable) {
  const routeParams: RouteParams = {};

  Object.keys(query).forEach((backKey) => {
    if (backKey.startsWith(BACK_QUERY_PREFIX)) {
      const key = backKey.substring(BACK_QUERY_PREFIX.length);
      routeParams[key] = query[backKey];
    }
  });

  return routeParams;
}

/**
 * Extracts the values from a query string that are not back route parameters
 * @param query The query
 * @example
 * const query = {
 *   'backRouteName': 'device-profile',
 *   'backRouteParam-deviceId': 10,
 *   'Status': 'Open'
 * };
 * const result = extractNonBackRouteParams(query);
 *
 * console.log(result);
 * // {
 * //   Status: 'Open'
 * // }
 */
export function extractNonBackRouteParams(query: Indexable) {
  const returnValues: RouteParams = {};

  Object.keys(query).forEach((key) => {
    if (
      key !== BACK_ROUTE_NAME_QUERY_KEY &&
      !key.startsWith(BACK_PARAM_PREFIX)
    ) {
      returnValues[key] = query[key];
    }
  });

  return returnValues;
}

/**
 * Maps a set of values to return route query parameters
 * @param values The values to map
 */
export function mapBackRouteParamsQuery(values: Indexable) {
  const query: Indexable = {};

  Object.keys(values).forEach((key) => {
    const backKey = `${BACK_PARAM_PREFIX}${key}`;
    query[backKey] = values[key];
  });

  return query;
}

/**
 * Maps a set of values to return route query parameters
 * @param values The values to map
 */
export function mapBackRouteQuery(values: Indexable) {
  const query: Indexable = {};

  Object.keys(values).forEach((key) => {
    const backKey = `${BACK_QUERY_PREFIX}${key}`;
    query[backKey] = values[key];
  });

  return query;
}

/**
 * Normalizes a route location into an object
 * @param route The route location to normalize
 * @returns The route location as an object.
 */
export function normalizeRouteLocation(route: RouteLocationRaw) {
  if (typeof route === 'string') {
    return {
      name: route,
    } as RouteLocationNamedRaw;
  }

  const routeName = route as RouteLocationNamedRaw;

  return {
    name: routeName.name,
    params: routeName.params,
  } as RouteLocationNamedRaw;
}

/**
 * Indicates if the given value is a route parameter token. Route parameter
 * tokens are values that start with `:`.
 * @param value The value to check
 * @returns `true` if the value is a route parameter token; otherwise, `false`
 * @example
 * console.log(isRouteParamToken(':deviceId'))
 * // Outputs: `true`
 *
 * console.log(isRouteParamToken(100))
 * // Outputs: `false`
 */
export function isRouteParamToken(
  value: RouteParamValueRaw | (string | number)[]
) {
  if (Array.isArray(value)) {
    value = value[0];
  }

  if (!value) {
    return false;
  }

  return value.toString().startsWith(':');
}

/**
 * Parses a route parameter token into a route parameter key
 * @param token The token to parse
 * @example
 * parseRouteParamToken(':device-id') // 'device-id'
 * parseRouteParamToken('id') // 'id'
 */
export function parseRouteParamToken(token: RouteParamValueRaw) {
  if (!isRouteParamToken(token)) {
    return token?.toString() || '';
  }

  // The first character in a route token parameter is `:` so that
  // leading character is stripped out to get the actual token
  return token?.toString().substring(1) || '';
}

/**
 * Resolves a set of route parameters to their actual values
 * @param params The set of route parameters to resolve
 * @param resolveFrom The set of values to resolve the tokens with. If no value is given,
 * then the route parameters from the current route are used.
 * @returns A set of route parameters with all actual values
 */
export function resolveRouteParams(
  params: Record<PropertyKey, string> | RouteParamsRaw | undefined,
  resolveFrom: RouteParams
) {
  if (!params) {
    return undefined;
  }
  const resolvedParams: RouteParams = {};

  Object.keys(params || {}).forEach((key) => {
    // A `param` that's a token starts with a `:`
    // Examples: `:id`, `:device-id`, `:test-id`
    const paramName = parseRouteParamToken(params[key]?.toString());
    resolvedParams[key] = resolveFrom?.[paramName] || '';
  });

  return resolvedParams;
}

/**
 * Resolves the parameters of a route and returns an object that can
 * be navigated to with the router
 * @param route The route with the parameter to resolve
 * @param resolveFrom The set of values to resolve the tokens with. If no value is given,
 * then the route parameters from the current route are used.
 * @returns A route with its parameter resolved to actual values
 */
export function resolveRoute(
  route: RouteLocationRaw,
  resolveFrom: RouteParams
) {
  const routeLocation = normalizeRouteLocation(route);

  return {
    name: routeLocation.name,
    params: resolveRouteParams(routeLocation.params, resolveFrom),
  } as RouteLocationRaw;
}

/**
 * Gets a route label by evaluating it as a function or just returning the label if it's a `string`.
 * @param label The route label to get
 * @returns The route label information
 */
export function getRouteLabel(
  label: RouteLabel | undefined
): RouteLabelInfo | undefined {
  if (typeof label === 'string') {
    // Normalize the string by creating a result object
    return {
      label,
    };
  }

  if (typeof label === 'function') {
    let result = label();

    // Normalize the string by creating a result object
    if (typeof result === 'string') {
      result = {
        label: result,
      };
    }

    return result;
  }

  return label;
}

/**
 * Defines a component with generics for improved intellisense
 * @param component The source component to create a generic component with
 * @returns A generically typed version of the given component
 */
export function defineGenericComponent<TProps = unknown>(component: any) {
  // A functional component is used here (i.e., one that returns a render function) because they're the
  // lightest component. We need to call `defineComponent`, and can't just cast the given instance, because there's
  // some crazy generics happening to make the type-inference work correctly.
  return defineComponent((props: Readonly<TProps>) => {
    // h() is short for hyperscript - which means "JavaScript that produces HTML (hypertext markup language)".
    // This name is inherited from conventions shared by many virtual DOM implementations. A more descriptive
    // name could be createVnode(), but a shorter name helps when you have to call this function many times
    // in a render function.
    //
    return () => h(component, props);
  });
}

/**
 * Gets the slots of a component that are typed in a way that allows the creation of dynamic
 * template slot name without syntax errors.
 * @param slots The slots
 * @returns A list of slot names and values that can be used for creating dynamic template slot names
 */
export function getSlots(slots: Slots) {
  return Object.keys(slots).map((name) => {
    return {
      // The name is cased to `any` because otherwise it will cause errors in dynamic template slot names
      name: name as any,
      value: slots[name],
    };
  });
}

/**
 * Build a `Query` object from a `Pagination` object that can be used for making API calls.
 * @param pagination The pagination to map
 * @returns A query object built from the pagination object
 */
export function buildQuery(pagination?: Pagination): Query {
  const query = {
    Page: pagination?.page,
    Size: pagination?.rowsPerPage,
  } as Query;

  if (pagination?.descending) {
    query.Descending = Boolean(pagination.descending);
  }

  // The `AppGrid` supports all of the slots defined by QTable. For example,
  // you can specify a slot to render a cell using it's column `name` like this:
  //
  // `<template #body-cell-name="props">Props: {{ props.row.name }}</template>`
  //
  // However, using nested field names like a Device Log's `device.name` will result
  // in a syntax error:
  //
  // `<template #body-cell-device.name="props">Props: {{ props.row.name }}</template>`
  //
  // because `body-cell-device.name` is not a valid attribute name. To get around this,
  // let developers specify a nested path with dashes like this:
  //
  // `<template #body-cell-device-name="props">Props: {{ props.row.name }}</template>`
  //
  // Sorting on a column with a name that has dashes, like `device-name`, will get sent
  // to the server as `sortBy=device.name`.
  if (pagination?.sortBy) {
    query.Sort = pagination?.sortBy.replace('-', '.');
  }

  return query;
}

/**
 * Builds a hierarchy of tree nodes from a hierarchy of models
 * @param models The model hierarchy to build the tree nodes from
 * @returns A hierarchy of tree nodes from a hierarchy of models
 */
export function buildTreeNodes<
  MTree extends TreeModel<MTreeNode, NodeType>,
  MTreeNode extends BaseModel,
  NodeType
>(models?: MTree[] | null): TreeNodeModel<MTree, NodeType>[] {
  const treeNodes: TreeNodeModel<MTree, NodeType>[] = [];

  models?.forEach((model) => {
    const treeNode = {
      id: model.id,
      label: model.name,
      // lazy: true,
      model,
      children: buildTreeNodes(model.children),
    };

    // treeNode.children?.forEach((child) => (child.lazy = true));
    treeNodes.push(treeNode);
  });

  return treeNodes;
}

/**
 * Flattens a hierarchy of tree nodes from a hierarchy
 * @param models The model hierarchy to flatten
 * @returns A flattened array of tree nodes from a hierarchy
 */
export function keyTreeNodesById<
  MTree extends TreeModel<MTreeNode, NodeType>,
  MTreeNode extends BaseModel,
  NodeType
>(
  models?: TreeNodeModel<MTree, NodeType>[] | null
): TreeNodeModelCache<MTree, NodeType> {
  const cache: TreeNodeModelCache<MTree, NodeType> = {};

  models?.forEach((treeNode) => {
    cache[treeNode.model.id!] = treeNode;

    const childCache = keyTreeNodesById(
      treeNode?.children as TreeNodeModel<MTree, NodeType>[]
    );
    Object.assign(cache, childCache);
  });

  return cache;
}

/**
 * Takes a model, model id, model list, or model id list and
 * normalizes it into an array of model ids
 * @param value The value to normalize
 * @returns An array of model ids
 */
export function normalizeModelIds(
  value: ModelIdType | ModelIdType[] | BaseModel | BaseModel[] | undefined
) {
  if (value === undefined) {
    return value;
  }

  const values = Array.isArray(value) ? value : [value];

  return values.map((item) => {
    if (typeof item === 'number') {
      return item;
    }

    return item?.id || 0;
  });
}

/**
 * Gets an object with key/value pairs from the given `source` that pass the given filter
 * @param source The source object
 * @param filter Determines if a property is included in the result object
 * @returns An object with key/value pairs from the given `source` that pass the given filter
 */
export function filterProps<T extends object>(
  source: T,
  filter: (value: T[KeyOf<T>], key: KeyOf<T>) => boolean
) {
  const indexableSource = source as Indexable;
  const result = {} as Indexable;

  Object.keys(source).forEach((key) => {
    const value = indexableSource[key];

    if (!filter || filter(value, key as KeyOf<T>)) {
      Object.defineProperty(result, key, {
        // Allow deleting this property later on because we don't these added properties sent to the server
        configurable: true,
        // This ensures it shows up in Pinia with Vue devtools
        enumerable: true,
        value: value,
        writable: true,
      });
    }
  });

  return result as T;
}

/**
 * Gets a list of items, from an enumeration type, that have the `label` and `value` for each enum entry.
 * The result can be bound to the `options` property of a select field.
 * @param enumType The enumeration type
 * @returns A list of items that have the `label` and `value` for each enum entry
 */
export function getEnumSelectOptions<T extends object>(
  enumType: T
): DisplayOption[] {
  const enumOptions = getSelectOptions(enumType, (key: string) => {
    // For enumerations we use the value as the `id` and `name` because that's the
    // display text for users to see and also the value to send to the server
    const enumValue = (enumType as Indexable)[key];
    return {
      name: enumValue,
      id: enumValue,
    };
  });

  // Remove the `Unknown` option
  return enumOptions.filter((enumOption) => enumOption.id !== 'Unknown');
}

/**
 * Gets the enumeration key for a given value
 * @param enumType The enumeration type
 * @param value The value to get the key for
 * @returns The enumeration key for the given value.
 * If the value does exist, then `undefined` is returned.
 */
export function getEnumKey<T extends object>(enumType: T, value: any) {
  return Object.keys(enumType).find(
    (key) => (enumType as Indexable)[key] === value
  );
}

/**
 * Gets a list of items that have and `id` and `name` for each enum entry.
 * The result can be bound to the `options` property of a select field.
 * @param value The value to get the display options for
 * @param map An optional map function for the items
 * @param sortDirection The optional direction to sort the items in. Default is `Ascending`.
 * @param sortType The optional type of sorting to use. Default is `OrdinalIgnoreCase`.
 * @returns A list of items that have the `label` and `value` for each enum entry
 */
export function mapGroupSelectOptions<T extends object>(
  value: T | T[] | undefined,
  sortDirection = SortDirection.Ascending,
  sortType = SortType.OrdinalIgnoreCase
): DisplayGroupOption[] {
  const options = getSelectOptions(value, undefined, sortDirection, sortType);
  return options.map((option) => {
    return {
      label: option.name,
      value: option.id,
    };
  });
}

/**
 * Gets a list of items that have and `id` and `name` for each enum entry.
 * The result can be bound to the `options` property of a select field.
 * @param value The value to get the display options for
 * @param map An optional map function for the items
 * @param sortDirection The optional direction to sort the items in. Default is `Ascending`.
 * @param sortType The optional type of sorting to use. Default is `OrdinalIgnoreCase`.
 * @returns A list of items that have the `label` and `value` for each enum entry
 */
export function getSelectOptions<T extends object>(
  value: T | T[] | undefined,
  map?: (item: any) => DisplayOption,
  sortDirection = SortDirection.Ascending,
  sortType = SortType.OrdinalIgnoreCase
): DisplayOption[] {
  const items = [] as DisplayOption[];
  const isValueArray = Array.isArray(value);
  const valueList = isValueArray ? value : Object.keys(value || ({} as object));

  valueList?.forEach((valueItem) => {
    let item;

    if (map) {
      item = map(valueItem);
    } else if (isValueArray) {
      // The item is a `ReadableModel` so auto-map the `name` and `id`
      item = {
        name: (valueItem as Indexable)['name'],
        id: (valueItem as Indexable)['id'],
      } as DisplayOption;
    } else {
      // The item is the key, so use that for the `id` and to get the `name`
      const key = valueItem as string;
      const parsedId = parseInt(key);

      item = {
        name: (value as Indexable)[key],
        id: isNaN(parsedId) ? key : parsedId,
      } as DisplayOption;
    }

    items.push(item);
  });

  const comparator = getReadableModelComparator(sortDirection, sortType);
  return items.sort(comparator);
}

/**
 * Gets a comparator function that can be used to compare `ReadableModel` objects
 * @param direction The optional direction to sort the items in. Default is `Ascending`.
 * @param type The optional type of sorting to use. Default is `OrdinalIgnoreCase`.
 * @returns A comparator function that can be used to compare `ReadableModel` objects.
 */
export function getReadableModelComparator(
  direction = SortDirection.Ascending,
  type = SortType.OrdinalIgnoreCase
) {
  const comparer = (a: DisplayOption, b: DisplayOption) => {
    let result;
    let aName = a.name || '';
    let bName = b.name || '';

    if (type === SortType.OrdinalIgnoreCase) {
      aName = aName.toLowerCase();
      bName = bName.toLowerCase();
    }

    if (aName > bName) {
      result = 1;
    } else if (bName > aName) {
      result = -1;
    } else {
      result = 0;
    }

    if (direction === SortDirection.Descending) {
      result *= -1;
    }

    return result;
  };

  return comparer;
}

/**
 * Parses a value into a primitive value or an array of primitive values
 * @param value The value to parse
 * @returns The parsed value as a primitive or array of primitive values
 */
export function parsePrimitiveOrArray(value: any): Primitive | Primitive[] {
  if (isNumericType(value)) {
    return parseInt(value as string);
  } else if (isBooleanType(value)) {
    return parseBoolean(value);
  } else if (Array.isArray(value)) {
    return value.map((item) => parsePrimitiveOrArray(item) as Primitive);
  } else {
    return value as string;
  }
}

/**
 * Parses a value into a boolean value.
 * @param value
 * @returns A boolean value parsed from the given value
 * @example
 * 'true'       // true
 * 'false'      // false
 * true         // true
 * false        // false
 * 0            // false
 * 1            // true
 * -1           // true
 * Object       // true
 * `null`       // false
 * `undefined`  // false
 */
export function parseBoolean(value: any) {
  if (typeof value === 'boolean') {
    return !!value;
  } else if (typeof value === 'string') {
    return value === 'true';
  } else if (typeof value === 'number') {
    return !!value;
  }

  return !!value;
}

/**
 * Determines if a value is a boolean type
 * @param value The value to check
 * @returns `true` if the given value is boolean; otherwise, `false`
 */
export function isBooleanType(value: any) {
  return (
    value === true || value === false || value === 'true' || value === 'false'
  );
}

/**
 * Determines if a value is a number type (whole or float)
 * @param value The value to check
 * @returns `true` if the given value is numeric; otherwise, `false`
 */
export function isNumericType(value: any) {
  return !isNaN(value);
}

/**
 * Determines if a given value is a query string parameter type
 * @param value The value to check
 * @returns `true` if the give value is a query string parameter type; otherwise, `false`.
 */
export function isQueryParamType(value: any) {
  return isPrimitiveType(value) || Array.isArray(value);
}

/**
 * Determines if a given value is a complex type
 * @param value The value to check
 * @returns `true` if the given value is a complex type; otherwise, false
 */
export function isComplexType(value: any) {
  return value !== null && value !== undefined && typeof value === 'object';
}

/**
 * Determines if a given value is a primitive type
 * @param value The value to check
 * @returns `true` if the give value is a primitive type; otherwise, `false`.
 */
export function isPrimitiveType(value: any) {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'bigint' ||
    typeof value === 'boolean'
  );
}

/**
 * Finds route children using a predicate as a filter. This function recursively looks at all children.
 * @param routes The routes to recursively check
 * @param predicate The predicate that determines if a given route is a match
 * @returns The routes that pass the given predicate
 */
export function findRoutes(
  routes: RouteLocationMatched[] | RouteRecordRaw[] | undefined,
  predicate?: (route: RouteRecordRaw) => boolean
) {
  const matchingChildren: RouteRecordRaw[] = [];

  routes?.forEach((child) => {
    if (!predicate || predicate(child)) {
      matchingChildren.push(child);
    }

    // Recursively check children
    if (child.children) {
      const recursiveMatchingChildren = findRoutes(child.children, predicate);
      matchingChildren.push(...recursiveMatchingChildren);
    }
  });

  return matchingChildren;
}

/**
 * Gets the sub-navigation routes for a given parent route name
 * @param parentName The name fo the parent route to get the sub-navigation routes for
 */
export function getSubNavRoutes(parentName: RouteRecordName | undefined) {
  const route = useRoute();
  const rootRoute = route.matched[0];

  const parentRoute = findRoutes(
    rootRoute.children,
    (route) => route.name === parentName
  )[0];

  const subNavRoutes = findRoutes(
    parentRoute?.children,
    (route) => !!route.meta?.subNavLabel
  );

  return subNavRoutes;
}

/**
 * Indicates if a route has a given component defined
 * @param componentName The component name to check
 * @param route The optional route to check. If not given, then the current route is used.
 * @returns `true` if the component name exists on the route; otherwise, `false`
 */
export function hasRouteComponent(
  componentName: string,
  route?: RouteLocationNormalizedLoaded
) {
  const currentMatches = route ? route.matched : useRoute().matched;
  const currentMatch = currentMatches[currentMatches.length - 1];

  if (!currentMatch.components) {
    return false;
  }

  return currentMatch.components[componentName] !== undefined;
}

/**
 * Gets an icon that corresponds to the status of a test
 * @param status The test status to get the icon for
 * @returns The icon for the corresponding test status
 */
export function getTestStatusIcon(status: TestStatus | undefined) {
  switch (status) {
    case TestStatus.Excluded:
      return 'remove_circle_outline';
    case TestStatus.Fail:
      return 'cancel';
    case TestStatus.Incomplete:
      return 'incomplete_circle';
    case TestStatus.Pass:
      return 'check_circle_outline';
    case TestStatus.Resolved:
      return 'checklist';
    case TestStatus.Skipped:
      return 'published_with_changes';
    case TestStatus.Unknown:
      return 'help_outline';
    default:
      return 'help_outline';
  }
}

/**
 * Gets a color that corresponds to the status of a test
 * @param status The test status to get the status for
 * @returns The color for the corresponding test status
 */
export function getTestStatusColor(status: TestStatus | undefined) {
  switch (status) {
    case TestStatus.Excluded:
      return 'warning-darker';
    case TestStatus.Fail:
      return 'negative';
    case TestStatus.Incomplete:
      return 'neutral-50';
    case TestStatus.Pass:
      return 'positive-darker';
    case TestStatus.Resolved:
      return 'positive-darker';
    case TestStatus.Skipped:
      return 'secondary';
    case TestStatus.Unknown:
      return 'neutral-50';
    default:
      return 'neutral-50';
  }
}

/**
 * Gets an icon that corresponds to the result status of a test
 * @param status The test result status to get the icon for
 * @returns The icon for the corresponding test result status
 */
export function getTestResultStatusIcon(status: TestResultStatus | undefined) {
  switch (status) {
    case TestResultStatus.Excluded:
      return 'remove_circle_outline';
    case TestResultStatus.Approved:
      return 'verified';
    case TestResultStatus.Resolved:
      return 'checklist';
    default:
      return 'help_outline';
  }
}

/**
 * Gets a color that corresponds to the result status of a test
 * @param status The test status to get the result status for
 * @returns The color for the corresponding test result status
 */
export function getTestResultStatusColor(status: TestResultStatus | undefined) {
  switch (status) {
    case TestResultStatus.Excluded:
      return 'warning-darker';
    case TestResultStatus.Approved:
      return 'positive-darker';
    case TestResultStatus.Resolved:
      return 'positive-darker';
    default:
      return 'neutral-50';
  }
}

/**
 * Gets the status of a test result from a commentary list. The most recent comment
 * is used to get the test status.
 * @param commentaryList The list of comments
 * @returns The test result status for the given commentary list
 */
export function getTestResultFromCommentary(
  commentaryList: DeviceTestResultCommentaryModel[] | undefined
) {
  if (!commentaryList?.length) {
    return undefined;
  }

  const mostRecentCommentary = commentaryList[commentaryList.length - 1];

  switch (mostRecentCommentary.commentaryType) {
    case CommentaryType.Approved:
      return TestResultStatus.Approved;
    case CommentaryType.Resolved:
      return TestResultStatus.Resolved;
    default:
      return undefined;
  }
}

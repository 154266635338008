import { useFuse } from '@vueuse/integrations/useFuse';
import { MaybeRef, resolveRef, resolveUnref } from '@vueuse/shared';
import { compareAsc, compareDesc, parse, parseISO } from 'date-fns';
import { ExtendedAttributeModel } from 'generated/DeviceService';
import {
  DisplayOption,
  ExtendedAttributeType,
  KeyOf,
  Method,
  NestedKeyOf,
  NonNullableAll,
  ReadableModel,
  SupportsExtendedAttributes,
  SupportsInit,
  UpdatableModel,
} from 'models/base';
import { BreadcrumbRoute, BreadcrumbRoutesLoaded } from 'models/breadcrumbs';
import { DateFormat, DateOptions, DateOptionsFormat } from 'models/date';
import { SelectOptions } from 'models/form';
import { PagedModel } from 'models/paged';
import {
  ParallelCallbackErrorState,
  ParallelCallbackLoadedState,
  ParallelCallbackLoadingState,
  ParallelCallbackSet,
  ParallelLoader,
} from 'models/parallel-callbacks';
import { QueryStringOptions } from 'models/query';
import { BackRouteQuery } from 'models/routing';
import { defineStore, DefineStoreOptionsBase, StateTree } from 'pinia';
import { computed, ref, Ref, unref, watch, WritableComputedRef } from 'vue';
import {
  LocationQuery,
  RouteLocationNamedRaw,
  RouteRecord,
  useRouter,
} from 'vue-router';
import { hasNotStateDecorator } from './decorators';
import { getRandomNumberInclusive } from './math';
import { flattenMembers } from './reflection';
import {
  mapBackRouteParamsQuery,
  extractBackRouteParams,
  extractBackRouteQuery,
  findRoutes,
  formatDate,
  isDateValid,
  matchDateTime,
  normalizeRouteLocation,
  resolveRouteParams,
  mapBackRouteQuery,
  extractNonBackRouteParams,
} from './utility';

/**
 * Provides read and write access to a date field stored in UTC and displays the value in the local time zone
 * @param target The target object with the date field
 * @param path The path to the date field
 * @param format The format of the date field. Default format is `DateFormat.ShortDate`.
 * @returns A reference that provides read and write access to a date field stored in UTC,
 * and displays the value in the local time zone
 */
export function useDateFormattedProp<T extends object>(
  target: Ref<T>,
  path: NestedKeyOf<T>,
  format = DateFormat.ShortDate
) {
  const modelProp = computed({
    get: () => Reflect.getPath(target.value, path as string),
    set: (value) => Reflect.setPath(target.value, path as string, value),
  });

  return useDateFormatted(modelProp, format);
}

/**
 * Provides read and write access to a date field stored in UTC and displays the value in the local time zone
 * @param utcDate The UTC date
 * @param format The format of the date field. Default format is `DateFormat.ShortDate`.
 * @returns A reference that provides read and write access to a date field stored in UTC,
 * and displays the value in the local time zone
 */
export function useDateFormatted(
  utcDate: Ref<string | undefined>,
  format = DateFormat.ShortDate
) {
  return computed({
    get: () => formatDate(utcDate.value, format),
    set(date) {
      let newValue = date;

      // Accept local dates
      if (newValue) {
        const parsedDate = parse(newValue, format, new Date());

        // Keep the current value if the date is invalid
        if (!isDateValid(parsedDate)) {
          return;
        }

        // Convert the date to UTC
        newValue = parsedDate.toISOString();
      }

      utcDate.value = newValue;
    },
  });
}

/**
 * A composable used for configuring date options for the `q-date` control
 * @param options
 * @returns A function that can be used at the options for the `q-date` control
 */
export function useDateOptions(options: DateOptions) {
  return (date: string) => {
    const minDate = unref(options.min);
    const maxDate = unref(options.max);

    if (!minDate && !maxDate) {
      return true;
    }

    const parsedDate = parse(date, DateOptionsFormat, new Date());
    let parsedMinDate: Date | undefined;
    let parsedMaxDate: Date | undefined;

    if (options.min) {
      parsedMinDate = parseISO(String(minDate));
      parsedMinDate = matchDateTime(parsedMinDate, parsedDate);

      if (options.minInclusive === false) {
        parsedMinDate.setDate(parsedMinDate.getDate() + 1);
      }
    }

    if (options.max) {
      parsedMaxDate = parseISO(String(maxDate));
      parsedMaxDate = matchDateTime(parsedMaxDate, parsedDate);

      if (options.maxInclusive === false) {
        parsedMaxDate.setDate(parsedMaxDate.getDate() + 1);
      }
    }

    // Only a minimum constraint
    if (parsedMinDate && !parsedMaxDate) {
      return compareAsc(parsedDate, parsedMinDate) >= 0;
    }

    // Only a maximum constraint
    if (!parsedMinDate && parsedMaxDate) {
      return compareAsc(parsedDate, parsedMaxDate) <= 0;
    }

    // A date range constraint
    return (
      compareAsc(parsedDate, parsedMinDate!) >= 0 &&
      compareDesc(parsedDate, parsedMaxDate!) <= 0
    );
  };
}

/**
 * Creates a filter and search results for use in a select field
 * @param options The display options used for the select field
 * @param isMatch An optional function that that's called for each option to determine if it
 * should show up in the results. If no function is given, then a case insensitive contains
 * search is used.
 * @returns An object with the filter and search results for use in a select field
 */
export function useSelectOptions(
  options: MaybeRef<
    DisplayOption[] | PagedModel<any> | ReadableModel[] | undefined
  >,
  isMatch?: (option: DisplayOption, inputValue: string) => boolean
): SelectOptions {
  const results = ref<any[]>([]);

  const updateResults = (searchValue: string) => {
    let allOptions: any[];
    const optionsValues = resolveUnref(options);

    // Support both arrays and paged models to make the developer's life easy
    if (Array.isArray(optionsValues)) {
      allOptions = [...optionsValues];
    } else {
      // Paged Models
      allOptions = [...(optionsValues?.items || [])];
    }

    // Check for a custom match function
    if (typeof isMatch === 'function') {
      results.value = allOptions.filter((option) =>
        isMatch(option as DisplayOption, searchValue)
      );

      return;
    }

    // Do a fuzzy search on the display name
    const searchResults = useFuse(searchValue, allOptions, {
      matchAllWhenSearchEmpty: true,
      fuseOptions: {
        // Include indexes of matched character to use for highlighting later on
        includeMatches: true,
        // Perfect match is `0`, so let's be a little flexible
        threshold: 0.2,
        keys: ['name'],
      },
    });

    results.value = searchResults.results.value.map((value) => value.item);
  };

  const filter = function (
    searchValue: string,
    doneFn: (callbackFn: () => void) => void
    // abortFn: () => void
  ) {
    doneFn(() => updateResults(searchValue));
  };

  updateResults('');

  return {
    items: results,
    filter,
  };
}

/**
 * Creates a reference that forwards all gets and sets to an extended
 * attribute with the given name
 * @example
 * const category = extendedAttribute(deviceStore.model, 'category');
 * category.value = 'Yeah!';
 *
 * const categoryExtensionAttribute = deviceStore.model.value
 *    .extensionAttributes.find(attr => attr.name === 'category');
 *
 * console.log(categoryExtensionAttribute.value); // Outputs `Yeah!`
 * @param model The model to get the extended attribute from
 * @param name The name of the extended attribute to get and set
 * @returns A reference that forwards all gets and sets to an extended attribute
 */
export function useExtendedAttribute<
  M extends UpdatableModel & SupportsExtendedAttributes
>(
  model: Ref<M>,
  name: KeyOf<M>,
  defaultValue: ExtendedAttributeType = ''
): WritableComputedRef<ExtendedAttributeType> {
  if (!model.value.extendedAttributes) {
    model.value.extendedAttributes = [];
  }

  let extendedAttribute = model.value.extendedAttributes?.find(
    (attr) => attr.name === name
  ) as NonNullableAll<ExtendedAttributeModel>;

  if (!extendedAttribute) {
    extendedAttribute = {
      name: name as string,
      value: defaultValue?.toString() || '',
    };

    model.value.extendedAttributes.push(extendedAttribute);
  }

  // The value need to be in a `ref` because otherwise the computed property
  // doesn't trigger consistently.
  //
  // We could avoid using this extra `ref` if the  getter/setter below looped through
  // `model.value.extendedAttributes` because `model` is a `ref`.
  //
  // However, we don't want to loop through `model.value.extendedAttributes` on each
  // get/set because it's called every time the user presses a key, and all of that
  // looping is unnecessary overhead.
  const attributeValue = ref(defaultValue as ExtendedAttributeType);

  return computed({
    get: () => attributeValue.value,
    set: (value) => {
      attributeValue.value = value;
      extendedAttribute.value = value?.toString();
    },
  });
}

/**
 * Gets the value of a property path, from a source object, and automatically
 * updates when the path changes
 * @param source The source object to get the property value from
 * @param path The path to get the value of
 * @returns The value of the property path on the source object
 */
export function useObjectPath(source: any, path: Ref<string> | string) {
  return computed({
    get: () => Reflect.getPath(source, unref(path)),
    set: (value) => Reflect.setPath(source, unref(path), value),
  });
}

/**
 * Use `setTimeout` as an async method
 * @param callback The callback to invoke with `setTimeout`
 * @param milliseconds The optional milliseconds to delay
 * @returns A promise that resolves after the given number of milliseconds.
 */
export async function useSetTimeout(callback: Method, milliseconds?: number) {
  return new Promise((resolve) =>
    setTimeout(() => {
      callback();
      resolve(undefined);
    }, milliseconds)
  );
}

/**
 * Delays code execution by a given number of milliseconds.
 * @param milliseconds The milliseconds to delay
 * @returns A promise that resolves after the given number of milliseconds.
 */
export async function useDelay(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Delays code execution by a given number of milliseconds.
 * @param minMilliseconds The minimum milliseconds to delay
 * @param maxMilliseconds The maximum milliseconds to delay
 * @returns A promise that resolves after the given number of milliseconds.
 */
export async function useRandomDelay(
  minMilliseconds: number,
  maxMilliseconds: number
) {
  const milliseconds = getRandomNumberInclusive(
    minMilliseconds,
    maxMilliseconds
  );

  return useDelay(milliseconds);
}

/**
 * Defines a class as a store and creates a "use" composable function for using the Pinia store.
 * The properties are proxied as store state and functions are proxied as store actions.
 * @id The unique id of the store
 * @storeFactory A factory function that creates a new instance of a class store.
 * @options Optional set of configuration options for the store.
 * @returns A composable function that can create a Pinia store based on the supplied class store.
 */
export function defineClassStore<Id extends string, CS extends StateTree>(
  id: Id,
  storeFactory: () => CS | SupportsInit<CS>,
  options?: DefineStoreOptionsBase<CS, CS>
) {
  const storeSetup = () => {
    const store = storeFactory();

    if ('init' in store) {
      store.init();
    }

    const stateAndMethods = flattenMembers(store, (key, prototype) => {
      // Ensure all functions "this" context is bound to the class instance
      if (typeof prototype[key] === 'function') {
        return prototype[key].bind(store);
      }

      if (hasNotStateDecorator(store, key)) {
        return undefined;
      }

      return prototype[key];
    });

    return stateAndMethods;
  };

  return defineStore<Id, CS>(id, storeSetup, options);
}

/**
 * A composable that has the value of the route to send the user back.
 * Here's how the back route is determined:
 *
 * 1. Check for a return route in the query string
 * 2. Use the previous breadcrumb
 * 3. Fallback to sending the user home
 */
export function useBackRoute() {
  const router = useRouter();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return computed(() => {
    // Casting this so that we'll get a build-time error if the route name
    // query parameter key is renamed
    const returnRouteQuery = router.currentRoute.value.query as BackRouteQuery;

    const returnQuery = extractBackRouteQuery(returnRouteQuery);
    const returnRouteName = returnRouteQuery.backRouteName;

    // First check if there's a return route in the query string
    if (returnRouteName) {
      return {
        name: returnRouteName,
        // Example extracted query parameter:
        // `returnParam-deviceId` key results in `deviceId` key
        params: extractBackRouteParams(returnQuery),
        query: returnQuery,
      } as RouteLocationNamedRaw;
    }

    // Use the previous breadcrumb to determine where to go
    const breadcrumbs = breadcrumbRoutes.value.breadcrumbs;
    let previous = breadcrumbs?.[breadcrumbs?.length - 1];

    if (!previous) {
      previous = breadcrumbs?.[0];
    }

    return {
      // Fallback to 'home' if there's no breadcrumb
      name: previous?.name || 'home',
      params: previous?.params,
      query: returnQuery,
    } as RouteLocationNamedRaw;
  });
}

/**
 * A composable that has the query string values for returning to the current route
 */
export function useBackHereQuery() {
  const router = useRouter();

  return computed(() => {
    const currentParams = router.currentRoute.value.params;
    // Exclude any "back route" query parameters because we're creating a
    // "new back route" and don't want the previous values lingering around
    const currentQuery = extractNonBackRouteParams(
      router.currentRoute.value.query
    );
    const backRouteParamsQuery = mapBackRouteParamsQuery(currentParams);
    const backRouteQuery = mapBackRouteQuery(currentQuery);

    return {
      backRouteName: router.currentRoute.value.name,
      ...backRouteParamsQuery,
      ...backRouteQuery,
    } as BackRouteQuery;
  });
}

/**
 * A composable that keys all of the routes by route name
 */
export function useRoutesByName() {
  const router = useRouter();

  return computed(() => {
    const cache: Record<PropertyKey, RouteRecord> = {};

    router.getRoutes().forEach((route) => {
      if (!route.name) {
        return;
      }

      cache[route.name] = route;
    });

    return cache;
  });
}

/**
 * Gets the current breadcrumb routes
 * @returns The current breadcrumb routes
 */
export function useBreadcrumbRoutes() {
  const router = useRouter();
  const routesByName = useRoutesByName();

  return computed(() => {
    const currentRouteParams = router.currentRoute.value.params;
    const currentRoute = router.currentRoute.value;
    const breadcrumbs = currentRoute.meta.breadcrumbs || [];

    // Build breadcrumb routes defined on the route
    const breadcrumbRoutes = breadcrumbs?.map((breadcrumb) => {
      const route = normalizeRouteLocation(breadcrumb);
      const routeMeta = routesByName.value[route.name!].meta;

      return {
        label: routeMeta.breadcrumbLabel,
        name: route.name,
        params: resolveRouteParams(route.params, currentRouteParams),
      } as BreadcrumbRoute;
    });

    // Return a list of breadcrumbs and the active breadcrumb separately
    return {
      breadcrumbs: breadcrumbRoutes,
      active: {
        label: currentRoute.meta.breadcrumbLabel,
        name: currentRoute.name || undefined,
        params: resolveRouteParams(currentRoute.params, currentRouteParams),
      },
    } as BreadcrumbRoutesLoaded;
  });
}

/**
 * Gets the main navigation routes
 * @returns The main navigation routes
 */
export function useMainNavRoutes() {
  const router = useRouter();

  return computed(() => {
    const rootRoute = router.currentRoute.value.matched[0];

    const mainNavRoutes = findRoutes(
      rootRoute.children,
      (route) => !!route.meta?.navLabel
    );

    return mainNavRoutes;
  });
}

/**
 * Gets the active main navigation route
 * @returns The active main navigation route
 */
export function useActiveMainNavRoute() {
  const router = useRouter();

  return computed(() => {
    return router.currentRoute.value.matched?.filter(
      (matched) => !!matched.meta.navLabel
    )[0];
  });
}

/**
 * Uses a reference that allows reading from and writing to the query string
 * @param options The options to use for the query string
 * @returns A reference that allows reading from and writing to the query string
 */
export function useQueryString(
  options?: QueryStringOptions
): WritableComputedRef<LocationQuery> {
  const router = useRouter();

  return computed({
    get: () => router.currentRoute.value.query,
    set: (value) => {
      if (options?.replaceHistory) {
        router.replace({ query: value });
      } else {
        router.push({ query: value });
      }
    },
  });
}

/**
 * A composable for running multiple callbacks in parallel while tracking the
 * error and loading state of each callback
 * @param callbacks The callbacks to run in parallel
 * @returns A object for running multiple callbacks in parallel while tracking the
 * error and loading state of each callback
 */
export function useParallelLoader<T>(
  callbacks: MaybeRef<ParallelCallbackSet<T>>
): ParallelLoader<T> {
  const loadingInProgress = ref(false);
  const loaded = ref<ParallelCallbackLoadedState<T>>({});
  const loading = ref<ParallelCallbackLoadingState>({});
  const errors = ref<ParallelCallbackErrorState>({});

  const reset = function () {
    loaded.value = {};
    loading.value = {};
    errors.value = {};
  };

  const loadOne = async function (key: string) {
    loading.value = { ...loading.value, [key]: true };

    try {
      const callback = resolveUnref(callbacks)[key];
      const result = await callback();
      loaded.value = { ...loaded.value, [key]: result };
      return result;
    } catch (error: any) {
      errors.value = { ...errors.value, [key]: error };
    } finally {
      loading.value = { ...loading.value, [key]: false };
    }
  };

  const loadAll = async function () {
    if (loadingInProgress.value) {
      return [];
    }

    const loadingQueue: Promise<T | undefined>[] = [];
    let results: (T | undefined)[] = [];

    Object.keys(resolveUnref(callbacks)).forEach((key) => {
      if (shouldLoad(key)) {
        loadingInProgress.value = true;
        loadingQueue.push(loadOne(key));
      }
    });

    results = await Promise.all(loadingQueue);
    loadingInProgress.value = false;

    return results;
  };

  const shouldLoad = function (key: string) {
    return !loading.value[key] && !loaded.value[key] && !errors.value[key];
  };

  const totalCount = computed(
    () => Object.keys(resolveUnref(callbacks)).length
  );

  const completedCount = computed(() => {
    return Object.keys(resolveUnref(callbacks)).filter((key) => {
      return !loading.value[key] && !errors.value[key];
    }).length;
  });

  const completedPercent = computed(() => {
    if (totalCount.value === 0) {
      return 0;
    }

    return completedCount.value / totalCount.value;
  });

  watch(resolveRef(callbacks), () => reset(), { immediate: true });

  return {
    loadingInProgress,
    loading,
    loaded,
    errors,
    totalCount,
    completedCount,
    completedPercent,
    loadOne,
    loadAll,
    shouldLoad,
    reset,
  };
}

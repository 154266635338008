/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BaseRule {
  type?: string | null;
}

export interface BaselineField {
  /** @format double */
  baseline?: number;
  units?: string | null;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface Calculation {
  value: string;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface CalculationResult {
  value?: any;
  error?: string | null;
}

export interface Checkbox {
  value: boolean;
  defaultValue?: boolean;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface CompareToBaseline {
  /** @format double */
  lowerLimit?: number;
  /** @format double */
  upperLimit?: number;
  /** @format double */
  baseline?: number;
  units?: string | null;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export enum Comparison {
  Unknown = "Unknown",
  Equal = "Equal",
  GreaterThan = "GreaterThan",
  GreaterThanOrEqual = "GreaterThanOrEqual",
  LessThan = "LessThan",
  LessThanOrEqual = "LessThanOrEqual",
  Not = "Not",
  NotEqual = "NotEqual",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  Contains = "Contains",
  MatchesRegex = "MatchesRegex",
  EqualsCaseInsensitive = "EqualsCaseInsensitive",
  NullOrEmpty = "NullOrEmpty",
}

export interface Condition {
  fieldName?: string | null;
  operation?: Comparison;
  value?: string | null;
  type?: string | null;
}

export interface ConditionalSection {
  fields?: TestFieldBase[] | null;
  value: boolean;
  defaultValue?: boolean;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface Conditions {
  condition?: Condition;
  logicOperation?: LogicOperation;
  fieldName?: string | null;
  operation?: Comparison;
  value?: string | null;
  type?: string | null;
}

export interface ConstantNumber {
  /** @format double */
  value: number;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface ConstantString {
  value: string;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface ConstantWholeNumber {
  /** @format int32 */
  value: number;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface DateInput {
  /** @format date-time */
  value: string;
  /** @format date-time */
  defaultValue?: string;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export enum DeviceType {
  Unknown = "Unknown",
  AnalyticalXRay = "Analytical X-Ray",
  Barometer = "Barometer",
  BoneDensitometryUnit = "Bone Densitometry Unit",
  CabinetXRay = "Cabinet X-Ray",
  CodeCrashCart = "Code/Crash Cart",
  CT = "CT",
  DailyQADevice = "Daily QA Device",
  Dental = "Dental",
  Densitometer = "Densitometer",
  DiagnosticReviewWorkstation = "Diagnostic Review Workstation",
  DICOMPrinter = "DICOM Printer",
  DigitalRadiography = "Digital Radiography",
  DoseCalibrator = "Dose Calibrator",
  Electrometer = "Electrometer",
  ElectronMicroscope = "Electron Microscope",
  ElectronicMedicalRecordSystem = "Electronic Medical Record System",
  Fluoroscopy = "Fluoroscopy",
  GammaCamera = "Gamma Camera",
  Generic = "Generic",
  IMRTQADevice = "IMRT QA Device",
  InVivoDosimetrySystem = "In-Vivo Dosimetry system",
  IonChamber = "Ion Chamber",
  LinearAccelerator = "Linear Accelerator",
  Mammography = "Mammography",
  MUCalculationSoftware = "MU Calculation Software",
  ParticleAccelerator = "Particle Accelerator",
  PersonnelMonitoringEquipment = "Personnel Monitoring Equipment",
  PETCT = "PET CT",
  Phantom = "Phantom",
  SpecializedSetupEquipment = "Specialized Setup Equipment",
  SPECTCT = "SPECT/CT",
  SPECT = "SPECT",
  StereotacticBreastBiopsy = "Stereotactic Breast Biopsy",
  SurveyMeter = "Survey Meter",
  Thermometer = "Thermometer",
  ThyroidProbe = "Thyroid Probe",
  Tomosynthesis = "Tomosynthesis",
  TreatmentPlanningSystem = "Treatment Planning System",
  MRI = "MRI",
  Ultrasound = "Ultrasound",
  UltrasoundProbe = "Ultrasound Probe",
  XRayIrradiator = "X-Ray Irradiator",
  Xenon = "Xenon",
  WellChamber = "Well Chamber",
  WellCounter = "Well Counter",
  GammaCounter = "Gamma Counter",
  ProtectiveAprons = "Protective Aprons",
  GammaKnife = "Gamma Knife",
  Brachytherapy = "Brachytherapy",
}

export interface DifferenceFromBaseline {
  /** @format double */
  limit?: number;
  /** @format double */
  baseline?: number;
  units?: string | null;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface ExampleSchemaObjects {
  schemas?: ExampleTemplateSchemas;
  sections?: ExampleTemplateSections;
  fields?: ExampleTemplateFields;
  conditions?: ExampleTemplateFieldConditions;
}

export interface ExampleTemplateFieldConditions {
  ruleSet?: Conditions;
  condition?: Condition;
  baseRule?: BaseRule;
  fieldValueCondition?: FieldValueCondition;
}

export interface ExampleTemplateFields {
  testFieldBase?: TestFieldBase;
  wholeNumberInput?: WholeNumberInput;
  numberInput?: NumberInput;
  stringInput?: StringInput;
  checkbox?: Checkbox;
  dateInput?: DateInput;
  passFailInput?: PassFailInput;
  baselineField?: BaselineField;
  differenceFromBaseline?: DifferenceFromBaseline;
  compareToBaseline?: CompareToBaseline;
  constantString?: ConstantString;
  constantWholeNumber?: ConstantWholeNumber;
  constantNumber?: ConstantNumber;
  calculation?: Calculation;
  select?: Select;
  radioList?: RadioList;
}

export interface ExampleTemplateSchemas {
  dataTestSchema?: TestSchema;
}

export interface ExampleTemplateSections {
  section?: Section;
  conditionalSection?: ConditionalSection;
}

export interface FieldValueCondition {
  conditionType?: IFieldConditionType;
  fieldName?: string | null;
  operation?: Comparison;
  value?: string | null;
  type?: string | null;
}

export enum Frequency {
  Unknown = "Unknown",
  Daily7Days = "Daily (7 Days)",
  DailyMonFri = "Daily (Mon-Fri)",
  Biweekly = "Biweekly",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Semiannual = "Semiannual",
  Yearly = "Yearly",
}

export enum HttpStatusCode {
  Continue = "Continue",
  SwitchingProtocols = "SwitchingProtocols",
  Processing = "Processing",
  EarlyHints = "EarlyHints",
  OK = "OK",
  Created = "Created",
  Accepted = "Accepted",
  NonAuthoritativeInformation = "NonAuthoritativeInformation",
  NoContent = "NoContent",
  ResetContent = "ResetContent",
  PartialContent = "PartialContent",
  MultiStatus = "MultiStatus",
  AlreadyReported = "AlreadyReported",
  IMUsed = "IMUsed",
  Ambiguous = "Ambiguous",
  Moved = "Moved",
  Redirect = "Redirect",
  RedirectMethod = "RedirectMethod",
  NotModified = "NotModified",
  UseProxy = "UseProxy",
  Unused = "Unused",
  TemporaryRedirect = "TemporaryRedirect",
  PermanentRedirect = "PermanentRedirect",
  BadRequest = "BadRequest",
  Unauthorized = "Unauthorized",
  PaymentRequired = "PaymentRequired",
  Forbidden = "Forbidden",
  NotFound = "NotFound",
  MethodNotAllowed = "MethodNotAllowed",
  NotAcceptable = "NotAcceptable",
  ProxyAuthenticationRequired = "ProxyAuthenticationRequired",
  RequestTimeout = "RequestTimeout",
  Conflict = "Conflict",
  Gone = "Gone",
  LengthRequired = "LengthRequired",
  PreconditionFailed = "PreconditionFailed",
  TooLarge = "RequestEntityTooLarge",
  RequestUriTooLong = "RequestUriTooLong",
  UnsupportedMediaType = "UnsupportedMediaType",
  RequestedRangeNotSatisfiable = "RequestedRangeNotSatisfiable",
  ExpectationFailed = "ExpectationFailed",
  MisdirectedRequest = "MisdirectedRequest",
  UnprocessableEntity = "UnprocessableEntity",
  Locked = "Locked",
  FailedDependency = "FailedDependency",
  UpgradeRequired = "UpgradeRequired",
  PreconditionRequired = "PreconditionRequired",
  TooManyRequests = "TooManyRequests",
  RequestHeaderFieldsTooLarge = "RequestHeaderFieldsTooLarge",
  UnavailableForLegalReasons = "UnavailableForLegalReasons",
  InternalServerError = "InternalServerError",
  NotImplemented = "NotImplemented",
  BadGateway = "BadGateway",
  ServiceUnavailable = "ServiceUnavailable",
  GatewayTimeout = "GatewayTimeout",
  HttpVersionNotSupported = "HttpVersionNotSupported",
  VariantAlsoNegotiates = "VariantAlsoNegotiates",
  InsufficientStorage = "InsufficientStorage",
  LoopDetected = "LoopDetected",
  NotExtended = "NotExtended",
  NetworkAuthenticationRequired = "NetworkAuthenticationRequired",
}

export enum IFieldConditionType {
  Unknown = "Unknown",
  Visible = "Visible",
  Calculation = "Calculation",
}

export interface ITestSection {
  fields?: TestFieldBase[] | null;
  type?: string | null;
}

export enum LogicOperation {
  Unknown = "Unknown",
  And = "and",
  Or = "or",
}

export interface NameModel {
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface NumberInput {
  /** @format double */
  value: number;
  /** @format double */
  defaultValue?: number;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface PassFailInput {
  passLabel?: string | null;
  failLabel?: string | null;
  value: boolean;
  defaultValue?: boolean;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface RadioList {
  items?: Record<string, string>;
  value: string;
  defaultValue?: string | null;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface Section {
  fields?: TestFieldBase[] | null;
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface Select {
  items?: Record<string, string>;
  value: string;
  defaultValue?: string | null;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface StringInput {
  value: string;
  defaultValue?: string | null;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface TestCalculcation {
  name?: string | null;
  /** @format int32 */
  order?: number;
  calculcation?: string | null;
}

export interface TestContext {
  /** @format int64 */
  deviceTestConfigId?: number;
  /** @format int64 */
  testResultId?: number;
  setupContext?: Record<string, any>;
  inputContext?: Record<string, any>;
  calculationContext?: Record<string, CalculationResult>;
}

export interface TestFieldBase {
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export interface TestListItemModel {
  /** @format int64 */
  id?: number;
  name?: string | null;
  templates?: NameModel[] | null;
}

export interface TestModel {
  /** @format int32 */
  order?: number;
  testTemplates?: TestTemplateModel[] | null;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface TestSchema {
  sections?: ITestSection[] | null;
  type?: string | null;
}

export interface TestSetListItemModel {
  tests?: TestListItemModel[] | null;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface TestSetModel {
  recommendedFrequency?: Frequency;
  deviceTypes?: DeviceType[] | null;
  tests?: TestModel[] | null;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface TestSetupSchema {
  requiredDeviceAttributes?: string[] | null;
  sections?: ITestSection[] | null;
  type?: string | null;
}

export interface TestTemplateContextModel {
  testTemplate?: TestTemplateModel;
  testContext?: TestContext;
}

export interface TestTemplateModel {
  templateSchema?: TestTemplateSchema;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface TestTemplateSchema {
  name?: string | null;
  setup?: TestSetupSchema;
  test?: TestSchema;
  calculations?: TestCalculcation[] | null;
  type?: string | null;
}

export interface WholeNumberInput {
  /** @format int32 */
  value: number;
  /** @format int32 */
  defaultValue?: number;
  name: string;
  label: string;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/test-runner" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: Iterable<any> = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Enzee.Backend.TestRunner.Api
 * @version 1.0
 * @baseUrl /test-runner
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  ready = {
    /**
     * No description
     *
     * @tags Ready
     * @name Get
     * @request GET:/ready
     */
    get: (
      query?: {
        refresh?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, string>, any>({
        path: `/ready`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ready
     * @name GetEcho
     * @request GET:/echo
     */
    getEcho: (
      query?: {
        status?: HttpStatusCode;
        msg?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/echo`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  testContext = {
    /**
     * @description Gets a Enzee.Backend.TestRunner.Shared.Models.TestContext by deviceTestConfigId. If Id doesn't exist, returns NotFound.
     *
     * @tags TestContext
     * @name GetById
     * @summary Gets a Enzee.Backend.TestRunner.Shared.Models.TestContext by deviceTestConfigId. If Id doesn't exist, returns NotFound.
     * @request GET:/test-context/{deviceTestConfigId}/{testResultId}
     */
    getById: (deviceTestConfigId: number, testResultId: number, params: RequestParams = {}) =>
      this.request<TestContext, any>({
        path: `/test-context/${deviceTestConfigId}/${testResultId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Saves Enzee.Backend.TestRunner.Shared.Models.TestContext to Table Storage.
     *
     * @tags TestContext
     * @name Save
     * @summary Saves Enzee.Backend.TestRunner.Shared.Models.TestContext to Table Storage.
     * @request POST:/test-context/{deviceTestConfigId}
     */
    save: (deviceTestConfigId: number, data: TestContext, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/test-context/${deviceTestConfigId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  testSet = {
    /**
     * @description Gets a list of Enzee.Backend.TestRunner.Shared.Models.TestSetListItemModels
     *
     * @tags TestSet
     * @name GetList
     * @summary Gets a list of Enzee.Backend.TestRunner.Shared.Models.TestSetListItemModels
     * @request GET:/test-sets
     */
    getList: (
      query?: {
        DeviceType?: DeviceType;
      },
      params: RequestParams = {},
    ) =>
      this.request<TestSetListItemModel[], any>({
        path: `/test-sets`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a Enzee.Backend.TestRunner.Shared.Models.TestSetModel by ID
     *
     * @tags TestSet
     * @name GetById
     * @summary Gets a Enzee.Backend.TestRunner.Shared.Models.TestSetModel by ID
     * @request GET:/test-sets/{testSetId}
     */
    getById: (testSetId: number, params: RequestParams = {}) =>
      this.request<TestSetModel, any>({
        path: `/test-sets/${testSetId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  testTemplates = {
    /**
     * @description Gets a Enzee.Backend.TestRunner.Shared.Models.TestTemplateModel by ID
     *
     * @tags TestTemplates
     * @name GetById
     * @summary Gets a Enzee.Backend.TestRunner.Shared.Models.TestTemplateModel by ID
     * @request GET:/test-templates/{id}
     */
    getById: (id: number, params: RequestParams = {}) =>
      this.request<TestTemplateModel, any>({
        path: `/test-templates/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a Enzee.Backend.TestRunner.Shared.Models.TestTemplateModel by deviceTestConfigId
     *
     * @tags TestTemplates
     * @name GetGetTestTemplateAndContextByIdById
     * @summary Gets a Enzee.Backend.TestRunner.Shared.Models.TestTemplateModel by deviceTestConfigId
     * @request GET:/test-templates/{templateId}/{deviceTestConfigId}/{testResultId}
     */
    getGetTestTemplateAndContextByIdById: (
      templateId: number,
      deviceTestConfigId: number,
      testResultId: number,
      params: RequestParams = {},
    ) =>
      this.request<TestTemplateContextModel, any>({
        path: `/test-templates/${templateId}/${deviceTestConfigId}/${testResultId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestTemplates
     * @name GetModels
     * @request GET:/models
     */
    getModels: (params: RequestParams = {}) =>
      this.request<ExampleSchemaObjects, any>({
        path: `/models`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a list of Enzee.Backend.TestRunner.Shared.Models.TestTemplateModel by IDs
     *
     * @tags TestTemplates
     * @name GetList
     * @summary Gets a list of Enzee.Backend.TestRunner.Shared.Models.TestTemplateModel by IDs
     * @request GET:/test-templates/list
     */
    getList: (
      query?: {
        ids?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<TestTemplateModel[], any>({
        path: `/test-templates/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}

<template>
  <component :is="`enz-${$props.name}`">
    <slot></slot>
  </component>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
</script>

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ComputedRef, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export interface WebSocket {
  /**
   * Opens the web socket connection
   * @returns A promise that resolves after the connection is opened
   */
  open: () => Promise<void>;

  /**
   * Closes the web socket connection
   * @returns A promise that resolves after the connection is closed
   */
  close: () => Promise<void>;

  /**
   * Sends data to the server
   * @param methodName The name of the method to call
   * @param args The data to send
   * @returns A promise that resolves after the data is sent
   */
  send: (methodName: string, ...args: any[]) => Promise<any>;

  /**
   * The current data, keyed by method name
   */
  data: ComputedRef<Record<string, any>>;

  /**
   * The status of the connection
   */
  status: ComputedRef<WebSocketStatus | undefined>;

  /**
   * The current error
   */
  error: ComputedRef<Error | undefined>;

  /**
   * The id of the connection
   */
  connectionId: ComputedRef<string | undefined>;

  /**
   * Register an event handler
   */
  onEvent: (...args: any[]) => any;

  /**
   * Unregister an event handler
   * @param methodName The name of the method to unregister the handler for
   */
  offEvent: (methodName: string) => void;
}

export enum WebSocketStatus {
  Open,
  Closed,
  Connecting,
  Reconnecting,
}

export interface WebSocketOptions {
  logLevel?: LogLevel;
  autoReconnect?: boolean;
  autoDisconnect?: boolean;
}

/**
 * A composable for interacting with web sockets
 * @param url The URL to connect to
 * @param eventNames The names of the events to listen for data from
 * @param options The options for the connection
 * @returns A composable for interacting with web sockets
 */
export function useWebSocket(url: string, options?: WebSocketOptions) {
  const router = useRouter();
  const data = ref<any>();
  const status = ref<WebSocketStatus>(WebSocketStatus.Closed);
  const error = ref<Error | undefined>();

  async function open() {
    await connection.start();
    status.value = WebSocketStatus.Open;
  }

  async function close() {
    await connection.stop();
  }

  async function autoClose() {
    if (options?.autoDisconnect !== false) {
      await close();
    }
  }

  async function send(methodName: string, ...args: any[]) {
    const result = await connection.invoke.apply(connection, [
      methodName,
      ...args,
    ]);
    data.value = result;
    return result;
  }

  const connectionBuilder = new HubConnectionBuilder()
    .withUrl(url, {
      withCredentials: false,
    })
    // Default to logging information
    .configureLogging(options?.logLevel || LogLevel.Information);

  // Default to automatic reconnecting
  if (options?.autoReconnect !== false) {
    // TODO: Create an `IRetryPolicy` implementation for better control over reconnection behavior
    connectionBuilder.withAutomaticReconnect();
  }

  const connection = connectionBuilder.build();

  connection.onreconnecting((socketError) => {
    error.value = socketError;
    status.value = WebSocketStatus.Connecting;
  });

  connection.onreconnected(() => {
    status.value = WebSocketStatus.Reconnecting;
  });

  connection.onclose((socketError) => {
    error.value = socketError;
    status.value = WebSocketStatus.Closed;
  });

  // Automatically close the connection when the component unmounts, or the route name changes,
  // to safe-guard developers from forgetting to do it and just to let them be lazy and not think
  // about managing the connection
  onUnmounted(async () => await autoClose());
  watch(
    () => router.currentRoute.value.name,
    async () => await autoClose()
  );

  return {
    open,
    close,
    send,
    data,
    status,
    error,
    onEvent: connection.on.bind(connection),
    offEvent: connection.off.bind(connection),
  } as WebSocket;
}

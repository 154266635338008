import { MetadataPropertyKey } from 'models/reflection';
import 'reflect-metadata';

export const LoadingPropertyMetaKey = Symbol('LoadingProperty');
export const LoadingTriggerActiveMetaKey = Symbol('LoadingTriggerActive');

/**
 * Marks a property that will get set to `true` while an async function is in progress and `false` when
 * it completes or errors.
 * @returns A decorator used to mark a property a loading property
 */
export function LoadingProperty() {
  return (target: any, propertyKey: string) => {
    const loadingProperty = Reflect.getMetadata(LoadingPropertyMetaKey, target);

    if (loadingProperty) {
      throw new Error(
        "The '@LoadingProperty' decorator can only be used on one object property"
      );
    }

    Reflect.defineMetadata(LoadingPropertyMetaKey, propertyKey, target);
  };
}

export const ErrorPropertyMetaKey = Symbol('ErrorProperty');

/**
 * Marks a property that will get the error message of an async call.
 * @returns A decorator used to mark a property an error property
 */
export function ErrorProperty() {
  return (target: any, propertyKey: string) => {
    const errorProperty = Reflect.getMetadata(ErrorPropertyMetaKey, target);

    if (errorProperty) {
      throw new Error(
        "The '@ErrorProperty' decorator can only be used on one object property"
      );
    }

    Reflect.defineMetadata(ErrorPropertyMetaKey, propertyKey, target);
  };
}

/**
 * Marks a method as a loading trigger. This is used in combination with the
 * `LoadingProperty` and `ErrorProperty` decorators.
 * @returns A decorator factory
 */
export function LoadingTrigger() {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalFunc = descriptor.value;

    if (!Reflect.isAsyncFunction(originalFunc)) {
      throw new Error(
        "The '@LoadingTrigger' decorator can only be used on 'async' methods"
      );
    }

    descriptor.value = function (...args: any) {
      const activeLoadingTrigger = Reflect.getMetadata(
        LoadingTriggerActiveMetaKey,
        this
      );

      // No need to trigger loading if there's one in progress. This could happen in a recursive call
      // or when one method with this decorator calls other methods with this decorator.
      if (activeLoadingTrigger) {
        return originalFunc.apply(this, args);
      }

      Reflect.defineMetadata(LoadingTriggerActiveMetaKey, true, this);

      const loadingPropertyKey = Reflect.getMetadata(
        LoadingPropertyMetaKey,
        target
      );
      const errorPropertyKey = Reflect.getMetadata(
        ErrorPropertyMetaKey,
        target
      );

      Reflect.setRef(this, loadingPropertyKey, true);

      return originalFunc
        .apply(this, args)
        .catch((error: unknown) => {
          Reflect.setRef(this, errorPropertyKey, error);
        })
        .finally(() => {
          Reflect.setRef(this, loadingPropertyKey, false);
          Reflect.defineMetadata(LoadingTriggerActiveMetaKey, false, this);
        });
    };

    return descriptor;
  };
}

export const NotStateMetaKey = Symbol('NotState');

/**
 * Marks a property that should not be used as store state
 * @returns A decorator factory function
 */
export function NotState() {
  return (target: any, propertyKey: string) => {
    const excludedFromState =
      Reflect.getMetadata(NotStateMetaKey, target) || {};

    excludedFromState[propertyKey] = true;
    return Reflect.defineMetadata(NotStateMetaKey, excludedFromState, target);
  };
}

/**
 * Determines if a given property has the `@NotState` decorator applied to it
 * @param target The target object to check
 * @param propertyKey The name of the property to check
 * @returns `true` if the given property has the `@NotState` decorator applied to it; otherwise, false
 */
export function hasNotStateDecorator(
  target: any,
  propertyKey: MetadataPropertyKey
) {
  const excludedFromState = Reflect.getMetadata(NotStateMetaKey, target);
  return excludedFromState[propertyKey];
}

import { computed, onMounted, watch } from 'vue';
import { defineClassStore } from 'shared/composables';
import { createEventEmitter } from 'shared/events';
import { type Emitter } from 'mitt';
import { OffHandlers, OnHandlers } from 'models/events';
import { NotState } from 'shared/decorators';
import {
  ActionBarAction,
  ActionBarActionName,
  ActionBarEvents,
  ActionBarModel,
} from 'models/action-bar';
import { ModelStore } from './model';
import { resolveRouteParams } from 'shared/utility';

/**
 * Contains business logic for the main application actions. Actions
 * can be controlled from the meta data in each route.
 *
 * @example
 * const route = {
 *   path: '/devices/:id',
 *   component: () => import('layouts/Page.vue'),
 *   children: [
 *     {
 *       path: '/devices/:id',
 *       meta: {
 *         actionBar: {
 *           save: {
 *             label: 'Save Device'
 *           },
 *           cancel: {},
 *           reset: {}
 *         }
 *       }
 *       components: {
 *          default: () => import('pages/DeviceEdit.vue'),
 *          actionBar: () => import('components/AppActionBar.vue'),
 *       }
 *     }
 *   ]
 * }
 */
export class ActionBarStore extends ModelStore<ActionBarModel> {
  @NotState()
  private emitter!: Emitter<ActionBarEvents>;

  /**
   * Performs setup logic for the store
   * @returns The store setup
   */
  protected setup() {
    this.emitter = createEventEmitter<ActionBarEvents>();
    this.on = this.emitter.on;
    this.off = this.emitter.off;
    this.emit = this.emitter.emit;

    // Update the labels the first time a mounted component uses the action bar
    // NOTE: This happens only once
    onMounted(() => this.updateFromRoute());

    // Now update the labels every time a route changes
    watch(
      () => this.router.currentRoute.value.name,
      () => this.updateFromRoute()
    );

    return this.buildSetup({
      model: {
        // Define a set of standard actions, but developers can also add custom actions
        actions: [
          {
            name: 'create',
            label: 'Create',
            visible: false,
            color: 'primary',
            icon: 'add_circle_outline',
          },
          {
            name: 'edit',
            label: 'Edit',
            visible: false,
            icon: 'edit',
            color: 'primary',
          },
          {
            name: 'save',
            label: 'Save',
            visible: false,
            color: 'primary',
            icon: 'check',
          },
          {
            name: 'delete',
            label: 'Delete',
            visible: false,
            icon: 'delete',
            color: 'negative',
          },
          {
            name: 'cancel',
            label: 'Cancel',
            visible: false,
            color: 'secondary',
            icon: 'close',
          },
          {
            name: 'reset',
            label: 'Reset',
            visible: false,
            color: 'secondary',
            icon: 'undo',
            secondary: true,
          },
        ],
      },
    });
  }

  /**
   * Gets a map of actions keyed by type
   */
  actionsByName = computed(() => {
    const keyedByName = {} as Record<ActionBarActionName, ActionBarAction>;

    this.model.value.actions?.forEach(
      (action) => (keyedByName[action.name] = action)
    );

    return keyedByName;
  });

  /**
   * Gets all of the visible actions
   */
  visibleActions = computed(() => {
    return this.model.value.actions.filter(
      (action) => action.visible !== false
    );
  });

  /**
   * Gets the visible primary actions
   */
  visiblePrimaryActions = computed(() =>
    this.visibleActions.value.filter((action) => action.secondary !== true)
  );

  /**
   * Gets the visible secondary actions
   */
  visibleSecondaryActions = computed(() =>
    this.visibleActions.value.filter((action) => action.secondary === true)
  );

  /**
   * Indicates if the secondary actions are visible
   */
  secondaryActionsVisible = computed(
    () => this.visibleSecondaryActions.value.length > 0
  );

  /**
   * Updates the action bar using the action meta data in the current route
   */
  updateFromRoute() {
    this.reset();
    this.updateActionsFromRoute();
    this.sortActionsByRouteActions();
  }

  /**
   * Updates the current actions from the information in the current route
   */
  updateActionsFromRoute() {
    const routeActions = this.router.currentRoute.value.meta.actionBar;

    if (!routeActions) {
      return;
    }

    // Update the actions using meta data from the route. This allows
    // developers to control what the action bar shows from a route definition.
    Object.keys(routeActions).forEach((key) => {
      const actionName = key as ActionBarActionName;
      const routeAction = routeActions[actionName];

      if (!routeAction) {
        return;
      }

      // Default to `visible` because a developer's intention is to make the action
      // `visible` by specifying it on the route
      routeAction.visible = routeAction?.visible !== false;

      // Update the action bar action from the route action
      // Object.assign(action, routeAction);
      Object.keys(routeAction).forEach((key) => {
        if (this.actionsByName.value[actionName] === undefined) {
          this.addAction({
            name: actionName,
            ...routeAction,
          });
        } else {
          this.actionsByName.value[actionName][key] = routeAction[key];
        }
      });
    });
  }

  /**
   * Adds an action to the current model
   * @param action The action to add
   */
  addAction(action: ActionBarAction) {
    this.model.value.actions?.push({
      color: 'primary',
      ...action,
    });
  }

  /**
   * Sorts all of the actions by the order they're used in the route. This allows
   * developers to control the order of how they appear in the action bar just by
   * how they're ordered in the route meta data.
   */
  sortActionsByRouteActions() {
    if (!this.router.currentRoute.value.meta.actionBar) {
      return;
    }

    const routeActionNames = Object.keys(
      this.router.currentRoute.value.meta.actionBar
    );

    // Use the order of how actions are declared in the route meta data
    // to determine the sort order
    const comparer = (action1: ActionBarAction, action2: ActionBarAction) => {
      return (
        routeActionNames.indexOf(action1.name) -
        routeActionNames.indexOf(action2.name)
      );
    };

    this.model.value.actions?.sort(comparer);
  }

  /**
   * Adds an event handler
   */
  on!: OnHandlers<ActionBarEvents>;

  /**
   * Removes an event handler. Note: Event handlers are automatically removed when the
   * current component unmounts, so this is only needed when manual removal is required.
   */
  off!: OffHandlers<ActionBarEvents>;

  emit!: <Key extends keyof ActionBarEvents>(
    type: undefined extends ActionBarEvents[Key] ? Key : never
  ) => void;

  /**
   * Executes the given action
   * @param action The action to execute
   */
  exec(action: ActionBarAction) {
    // Check for an action that navigates
    if (action.to !== undefined) {
      this.router.push({
        name: action.to.name,
        params: resolveRouteParams(
          action.to.params,
          this.router.currentRoute.value.params
        ),
        query: this.router.currentRoute.value.query,
      });
    } else {
      this.emit(action.name);
    }
  }
}

export const useActionBarStore = defineClassStore(
  'action-bar',
  () => new ActionBarStore()
);

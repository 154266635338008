import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('layouts/Page.vue'),
    children: [
      {
        path: ':deviceId/logs',
        name: 'device-log-list',
        props: true,
        meta: {
          documentTitle: 'Device Logbook',
          breadcrumbLabel: 'Device Logbook',
          breadcrumbs: ['qa-today'],
          actionBar: {
            create: {
              label: 'New Log',
            },
          },
        },
        components: {
          default: () => import('pages/DeviceLogList.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':deviceId/logs/create',
        name: 'device-log-create',
        props: true,
        meta: {
          documentTitle: 'New Device Log',
          breadcrumbLabel: 'New Device Log',
          breadcrumbs: [
            'qa-today',
            { name: 'device-log-list', params: { deviceId: ':deviceId' } },
          ],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceLogCreate.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':deviceId/logs/:id',
        name: 'device-log-edit',
        props: true,
        meta: {
          documentTitle: 'Edit Device Log',
          breadcrumbLabel: 'Edit Device Log',
          breadcrumbs: [
            'qa-today',
            { name: 'device-log-list', params: { deviceId: ':deviceId' } },
          ],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceLogEdit.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
    ],
  },
] as RouteRecordRaw[];

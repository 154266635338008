<template>
  <AppComponent name="app-error-dialog">
    <AppDialog
      title="Oops! An Error Occurred"
      :cancel-visible="false"
      :dialog="dialog"
      header-color="negative"
      ok-color="negative"
    >
      <div>{{ message }}</div>
      <pre v-if="stackTrace">{{ stackTrace }}</pre>
    </AppDialog>
  </AppComponent>
</template>

<script lang="ts">
import AppDialog from 'components/AppDialog.vue';
import { Environment } from 'models/base';
import { useDialogPluginComponent } from 'quasar';
import { getEnvironment } from 'shared/utility';
import AppComponent from 'src/components/AppComponent.vue';
import { useTestSetStore } from 'stores/test-set';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'AppErrorDialog',
  emits: [...useDialogPluginComponent.emits],
  components: {
    AppComponent,
    AppDialog,
  },
  props: {
    error: {
      type: Object,
    },
  },
  setup(props) {
    const dialog = useDialogPluginComponent();
    const testSetStore = useTestSetStore();

    return {
      testSetStore,
      dialog,
      message: computed(() => props.error?.message || props.error?.toString()),
      stackTrace: computed(() => {
        const environment = getEnvironment();

        // Stack traces aren't in production error responses, but check anyway in case they end up here
        if (environment == Environment.Production) {
          return '';
        }

        return props.error?.stack;
      }),
    };
  },
});
</script>

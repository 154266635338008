import { useQuasar, type QVueGlobals } from 'quasar';
import { NotState } from 'shared/decorators';
import { useRouter, type Router } from 'vue-router';

/**
 * The base store that all stores inherit from
 */
export abstract class BaseStore<StoreSetup = unknown> {
  @NotState()
  protected quasar!: QVueGlobals;

  @NotState()
  protected router!: Router;

  @NotState()
  protected storeSetup!: StoreSetup;

  /**
   * Initializes the store
   */
  init() {
    this.quasar = useQuasar();
    this.router = useRouter();
    this.storeSetup = this.setup();
  }

  /**
   * Builds an object with the setup options for this store. This function is intended to be used in
   * the `setup()` function as a convenient way for developers to get type safety without declaring
   * complex generic types that occur in some derived classes.
   * @returns The store setup, or an empty object if no store setup is provided.
   */
  protected buildSetup(storeSetup?: StoreSetup): StoreSetup {
    return storeSetup || ({} as StoreSetup);
  }

  /**
   * Performs setup logic for the store. The name `setup` is used to be
   * consistent with the `setup` function used by Vue components.
   * @returns The store setup
   */
  protected setup(): StoreSetup {
    // The generics are complex in some derived classes and would be cumbersome
    // for developers to declare, and understand, if they don't have a lot of experience with generics
    // in TypeScript.
    const storeSetup = this.buildSetup();
    return storeSetup;
  }
}

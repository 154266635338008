import { MaybeComputedRef } from '@vueuse/core';

export type DateValue = string | readonly any[] | any | null;

export type DateReason =
  | 'add-day'
  | 'remove-day'
  | 'add-range'
  | 'remove-range'
  | 'mask'
  | 'locale'
  | 'year'
  | 'month';

export interface DateDetails {
  /**
   * The year of the date that the user has clicked/tapped on
   */
  year: number;
  /**
   * The month of the date that the user has clicked/tapped on
   */
  month: number;
  /**
   * The day of the month that the user has clicked/tapped on
   */
  day: number;
  /**
   * Object of properties of the range starting point (only if range)
   */
  from?: {
    /**
     * The year
     */
    year: number;
    /**
     * The month
     */
    month: number;
    /**
     * The day of month
     */
    day: number;
  };
  /**
   * Object of properties of the range ending point (only if range)
   */
  to?: {
    /**
     * The year
     */
    year: number;
    /**
     * The month
     */
    month: number;
    /**
     * The day of month
     */
    day: number;
  };
}

export enum DateFormat {
  ShortDate = 'MM/dd/yyyy',
  ShortDateTime = 'MM/dd/yyyy h:mmaa',
  ShortDateTimeWithTimeZone = 'MM/dd/yyyy h:mmaa zzzz',
  UTC = 'UTC',
}

export enum DateMask {
  ShortDate = 'MM/DD/YYYY',
  ShortDateTime = 'MM/DD/YYYY h:mmA',
}

export const DateOptionsFormat = 'yyyy/MM/dd';

export interface DateOptions {
  min?: MaybeComputedRef<string | undefined>;
  minInclusive?: MaybeComputedRef<boolean>;
  max?: MaybeComputedRef<string | undefined>;
  maxInclusive?: MaybeComputedRef<boolean>;
}

export interface DateComparisonOptions {
  inclusive?: boolean;
  useTime?: boolean;
}

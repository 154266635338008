import { ExtendedAttributeModel } from 'generated/DeviceService';
import { QDialog } from 'quasar';
import { Ref, UnwrapRef } from 'vue';

export type ModelIdType = number;
export type Method<ReturnType = any> = (...args: any[]) => ReturnType;

export type KeyOf<T> = keyof T;

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType &
    (string | number)]: RequireAll<ObjectType>[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<RequireAll<ObjectType>[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export enum Environment {
  Local = 'Local',
  Development = 'Dev',
  Test = 'Test',
  Production = 'Prod',
}

export type ExtractComponentProps<TComponent> = TComponent extends new () => {
  $props: infer P;
}
  ? P
  : never;

export type Indexable = { [key: string]: any };

export type UnwrapAll<SS> = {
  [K in keyof SS]: UnwrapRef<SS[K]>;
};

export type NonNullableAll<T> = {
  [Key in keyof T]: NonNullable<T[Key]>;
};

export type RequireAll<T> = {
  [Key in keyof T]-?: NonNullable<T[Key]>;
};

export interface BaseModel extends Indexable {
  id?: ModelIdType;
}

export interface ReadableModel extends BaseModel {
  name?: string | null;
}

export interface DisplayOption extends Indexable {
  id: any | null | undefined;
  name: any | null | undefined;
}

export interface DisplayGroupOption extends Indexable {
  label: any | null | undefined;
  value: any | null | undefined;
}

export interface DialogComponent {
  dialogRef: Ref<QDialog | undefined>;
  onDialogHide: () => void;
  onDialogOK: (payload?: any) => void;
  onDialogCancel: () => void;
}

export type ExtendedAttributeType = string | number | boolean | undefined;

export interface ExtendedAttributeNameValue<M extends BaseModel> {
  name: KeyOf<M>;
  defaultValue?: ExtendedAttributeType;
}
export type ExtendedAttributeArg<M extends BaseModel> =
  | KeyOf<M>
  | ExtendedAttributeNameValue<M>;

export type UpdatableModel = ReadableModel;

export type SupportsExtendedAttributes = {
  extendedAttributes?: ExtendedAttributeModel[] | null;
};

export interface AuditableModel extends BaseModel {
  createdAt?: string;
  createdBy?: string;
  createdById?: number;
  updatedAt?: string;
  updatedBy?: string;
  updatedById?: number;
  deletedAt?: string;
  deletedBy?: string;
  deletedById?: number;
}

export interface SelfReferencingModelWithChildren<M> extends BaseModel {
  // Allow nullable to make things easier for backend developers
  // so they don't have to worry about allowing `null` types
  children?: SelfReferencingModelWithChildren<M>[] | null;
}

export interface SelfReferencingModel<M>
  extends SelfReferencingModelWithChildren<M> {
  parent?: M;
}

export type SupportsInit<T> = T & {
  init(): void;
};

import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('layouts/Page.vue'),
    children: [
      {
        path: 'admin',
        name: 'admin',
        meta: {
          documentTitle: 'Admin',
          navLabel: 'Admin',
        },
        component: () => import('pages/Admin.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

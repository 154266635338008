<template>
  <router-view />
</template>

<script lang="ts">
import { captureErrors } from 'shared/errors';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  setup() {
    captureErrors();
  },
});
</script>

import { Api } from 'generated/TestRunnerService';
import { ModelIdType } from 'models/base';
import { getApiUrl } from 'shared/utility';
import { ref, Ref } from 'vue';
import { useWebSocket, WebSocket } from './web-socket';

// Hack to get a type definition for the APIs
const testRunnerApi = new Api();
export type TestSetApi = typeof testRunnerApi.testSet;
export type TestContextApi = typeof testRunnerApi.testContext;
export type TestTemplatesApi = typeof testRunnerApi.testTemplates;

/**
 * A factory for creating the API for test runner
 * @returns The API for test runner
 */
export function createTestRunnerApi() {
  const apiUrl = getApiUrl();
  return new Api({ baseURL: `${apiUrl}/test-runner` });
}

export interface TestContextWebSocket extends WebSocket {
  testResults: Ref<Record<PropertyKey, CalculationResultSet>>;
  updateInputContextItem: (
    context: TestInputContext
  ) => Promise<CalculationResultSet>;
}

export interface TestInputContext {
  DeviceTestConfigId?: ModelIdType;
  TestResultId?: ModelIdType;
  FieldName?: string;
  Value?: any;
}

export type CalculationResultSet = Record<string, CalculationResult>;

export interface CalculationResult {
  value?: any;
  error?: any;
}

/**
 * A composable for interacting with test results
 */
export function useTestContextWebSocket() {
  const apiUrl = getApiUrl();
  const webSocket = useWebSocket(`${apiUrl}/test-runner-hub/test-context`);
  const testResults = ref<Record<PropertyKey, CalculationResultSet>>({});

  return {
    ...webSocket,
    testResults,
    async updateInputContextItem(context: TestInputContext) {
      const results = await webSocket.send('UpdateInputContextItem', context);

      testResults.value = {
        ...testResults.value,
        // Key results by device test config id
        [context.DeviceTestConfigId!]: results,
      };

      return results;
    },
  } as TestContextWebSocket;
}

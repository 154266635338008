import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('layouts/Page.vue'),
    children: [
      {
        path: ':deviceId/configured-tests/create',
        name: 'device-test-config-create',
        props: true,
        meta: {
          documentTitle: 'Configure New Device Test',
          breadcrumbLabel: 'Configure New Device Test',
          breadcrumbs: [
            'qa-today',
            { name: 'device-profile', params: { id: ':deviceId' } },
          ],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceTestConfigCreate.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':deviceId/configured-tests/run',
        name: 'device-test-config-run',
        props: true,
        meta: {
          documentTitle: 'Run Tests',
          breadcrumbLabel: 'Run Tests',
          breadcrumbs: ['qa-today'],
          actionBar: {
            save: {
              label: 'Submit',
            },
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceTestRun.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':deviceId/configured-tests/:id',
        name: 'device-test-config-edit',
        props: true,
        meta: {
          documentTitle: 'Edit Device Test',
          breadcrumbLabel: 'Edit Device Test',
          breadcrumbs: [
            'qa-today',
            { name: 'device-profile', params: { id: ':deviceId' } },
          ],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceTestConfigEdit.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':deviceId/configured-tests/:deviceTestConfigId/results',
        name: 'device-test-config-results-list',
        props: true,
        meta: {
          documentTitle: 'Test History',
          breadcrumbLabel: 'Test History',
          breadcrumbs: [
            'qa-today',
            { name: 'device-profile', params: { id: ':deviceId' } },
          ],
        },
        components: {
          default: () => import('pages/DeviceTestResults.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':deviceId/configured-tests/:deviceTestConfigId/results/:deviceTestResultsId',
        name: 'device-test-config-results',
        props: true,
        meta: {
          documentTitle: 'Test Results',
          breadcrumbLabel: 'Test Results',
          breadcrumbs: [
            'qa-today',
            { name: 'device-profile', params: { id: ':deviceId' } },
            {
              name: 'device-test-config-results-list',
              params: {
                deviceId: ':deviceId',
                deviceTestConfigId: ':deviceTestConfigId',
              },
            },
          ],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceTestRun.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
    ],
  },
] as RouteRecordRaw[];

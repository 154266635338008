<template>
  <AppComponent name="app-dialog" class="app-dialog">
    <q-dialog
      :ref="dialogInstance.dialogRef"
      @hide="dialogInstance.onDialogHide"
    >
      <q-card class="app-dialog__content">
        <div class="app-dialog__header">
          <slot name="header">
            <q-item :class="headerBgColor">
              <q-item-section class="col-auto">
                <slot name="header-icon">
                  <q-icon color="neutral-100" :name="$props.icon" size="lg" />
                </slot>
              </q-item-section>
              <q-item-section class="col">
                <slot name="header-title">
                  <h4 class="q-ma-none text-neutral-100">{{ $props.title }}</h4>
                </slot>
              </q-item-section>
            </q-item>
          </slot>
        </div>

        <q-card-section class="app-dialog__body">
          <!-- Content is wrapped in `Suspense` to support async components (i.e., ones with `async` `setup` functions) -->
          <Suspense>
            <div>
              {{ $props.message }}
              <component
                v-if="$props.innerComponent"
                :is="$props.innerComponent"
                v-bind="innerComponentProps"
              ></component>
              <slot></slot>
            </div>
          </Suspense>
        </q-card-section>

        <q-inner-loading
          :showing="$props.loading"
          color="primary"
          label="Loading..."
        />

        <div class="app-dialog__actions">
          <q-separator />

          <slot name="actions" v-bind="dialogInstance">
            <q-card-actions align="right" class="col-1">
              <q-btn
                class="action"
                :color="$props.okColor"
                :label="$props.okLabel"
                :disable="$props.okDisable"
                @click="onDialogOk()"
              />
              <q-btn
                v-if="$props.cancelVisible"
                class="action"
                :color="$props.cancelColor"
                :label="$props.cancelLabel"
                :disable="$props.cancelDisable"
                @click="dialogInstance.onDialogCancel"
              />
            </q-card-actions>
          </slot>
        </div>
      </q-card>
    </q-dialog>
  </AppComponent>
</template>

<script lang="ts">
import { DialogComponent } from 'models/base';
import { useDialogPluginComponent } from 'quasar';
import AppComponent from 'src/components/AppComponent.vue';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'AppDialog',
  components: { AppComponent },
  emits: {
    // ...useDialogPluginComponent.emits,
    ok: () => Object as PropType<void>,
    hide: () => Object as PropType<void>,
  },
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    headerColor: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: 'help_outline',
    },
    okLabel: {
      type: String,
      default: 'OK',
    },
    okColor: {
      type: String,
      default: 'primary',
    },
    okDisable: {
      type: Boolean,
    },
    okClosesDialog: {
      type: Boolean,
      default: true,
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    cancelColor: {
      type: String,
      default: 'secondary',
    },
    cancelDisable: {
      type: Boolean,
    },
    cancelVisible: {
      type: Boolean,
      default: true,
    },
    innerComponent: {
      type: Object,
    },
    innerComponentProps: {
      type: Object as PropType<Record<string, any>>,
    },
    dialog: {
      type: Object as PropType<DialogComponent>,
    },
    payload: {},
    loading: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const dialog = useDialogPluginComponent();
    const dialogInstance = computed(() => props.dialog || dialog);

    return {
      dialogInstance,
      headerBgColor: computed(() => `bg-${props.headerColor}`),
      onDialogOk() {
        if (props.okClosesDialog) {
          dialogInstance.value.onDialogOK(props.payload);
        } else {
          context.emit('ok');
        }
      },
    };
  },
});
</script>

import { ReadableApi, ReadableChildApi } from 'api/base';
import type {
  Indexable,
  ModelIdType,
  NestedKeyOf,
  NonNullableAll,
  ReadableModel,
} from 'models/base';
import { PagedModel } from 'models/paged';
import type { Pagination } from 'models/pagination';
import type { Query } from 'models/query';
import {
  ReadableApiStoreSetup,
  ReadableChildApiStoreSetup,
} from 'models/store';
import { extend } from 'quasar';
import {
  ErrorProperty,
  LoadingProperty,
  LoadingTrigger,
  NotState,
} from 'shared/decorators';
import {
  buildQuery,
  filterProps,
  isValueEmpty,
  mapBackRouteQuery,
  parseValue,
} from 'shared/utility';
import { computed, ref, unref, watch } from 'vue';
import {
  LocationQueryRaw,
  RouteLocationNamedRaw,
  RouteParamsRaw,
} from 'vue-router';
import { ValidatableStore } from './validatable';

/**
 * A base store that supports reading and paging objects with an API
 */
export abstract class BaseReadableApiStore<
  //
  // These types are typically set by developers
  //
  API,
  M extends ReadableModel = ReadableModel,
  MList extends ReadableModel = ReadableModel,
  MPaged extends PagedModel<MPagedItems> = PagedModel<any>,
  Filter extends object = any,
  //
  // These types are inferred and typically aren't set by developers
  //
  MPagedItems extends ReadableModel = ReadableModel,
  StoreSetup extends ReadableApiStoreSetup<
    API,
    M,
    MList,
    MPaged,
    Filter,
    MPagedItems
  > = ReadableApiStoreSetup<API, M, MList, MPaged, Filter, MPagedItems>
> extends ValidatableStore<M, MList, MPaged, Filter, MPagedItems, StoreSetup> {
  @NotState()
  protected api!: API;

  /**
   * Initializes the store
   */
  init() {
    super.init();
    this.api = this.storeSetup.api;
    this.columns.value = this.storeSetup.columns || [];
    this.createRouteName.value = this.storeSetup.createRouteName || '';
    this.editRouteName.value = this.storeSetup.editRouteName || '';

    // Throw all errors so the global handler can deal with it
    watch(this.error, () => {
      if (this.error.value) {
        throw this.error.value;
      }
    });
  }

  /**
   * Indicates if data is currently loading
   */
  @LoadingProperty()
  loading = ref(false);

  /**
   * Show the current error
   */
  @ErrorProperty()
  error = ref<string>();

  /**
   * Indicates if the create button should be disabled
   */
  createDisable = ref(false);

  /**
   * The name of the route to use to create a new model
   */
  createRouteName = ref<string>();

  /**
   * The name of the route to use to edit a model
   */
  editRouteName = ref<string>();

  /**
   * Indicates if the previous page is enabled
   */
  previousPageDisable = computed(
    () =>
      !this.pagination.value.page ||
      this.pagination.value.page === 1 ||
      this.loading.value
  );

  /**
   * Indicates if the next page is enabled
   */
  nextPageDisable = computed(
    () =>
      !this.pagination.value.page ||
      this.pagination.value.page === this.totalPages.value ||
      this.loading.value
  );

  /**
   * Gets the params for the create route
   */
  getCreateRouteParams() {
    return {} as RouteParamsRaw;
  }

  /**
   * Navigates to create a new model
   */
  async create(query?: LocationQueryRaw) {
    if (!this.createRouteName) {
      console.warn(
        `The store needs to set the 'createRouteName' property in the 'setup'
       function to support calling 'store.create()'`,
        this
      );
    }

    // Keep any query string values when returning back here, like grid filters
    // and paging information
    const backRouteQuery = mapBackRouteQuery(
      this.router.currentRoute.value.query
    );

    return this.router.push({
      name: this.createRouteName.value,
      params: this.getCreateRouteParams(),
      query: extend(backRouteQuery, query),
    });
  }

  /**
   * Gets the route params for a given model
   * @param model The model to get the route params for
   * @returns The route params for the given model
   */
  getEditRouteParams(model: M | MPagedItems) {
    return {
      id: model.id,
    } as RouteParamsRaw;
  }

  /**
   * Gets the edit route for a given model
   * @param model The model to get edit route for
   * @returns The edit route for the given model
   */
  getEditRoute(model: M | MPagedItems) {
    return {
      name: this.editRouteName.value,
      params: this.getEditRouteParams(model),
    } as RouteLocationNamedRaw;
  }

  /**
   * Edits a model by navigating to the `editRouteName` route
   * and passing the model `id` as the route parameter.
   * @param model The optional model to edit. If no value is given, then
   * the current model is used.
   */
  edit(model?: M | MPagedItems) {
    model = model || this.model.value;

    if (model === undefined) {
      return;
    }

    if (!this.editRouteName) {
      console.warn(
        `The store needs to set the 'editRouteName' property in the 'setup'
       function to support calling 'store.edit(...)'`,
        this
      );
    }

    this.router.push({
      name: this.editRouteName.value,
      params: this.getEditRouteParams(model),
      query: this.router.currentRoute.value.query,
    });
  }

  /**
   * Builds a query from the current pagination value and the given pagination
   * @param pagination The pagination to build the query from
   * @returns A query object for a paged API call
   */
  buildQuery(pagination?: Pagination): Query {
    return buildQuery(pagination || this.pagination.value);
  }

  /**
   * Clears the paged models
   */
  clearPaged() {
    this.updatePaged({} as MPaged);
  }

  /**
   * Updates the current paged models
   * @param pagedModels The new paged models
   * @param pagination The optional new pagination to update
   */
  updatePaged(pagedModels: MPaged, pagination?: Pagination) {
    this.pagination.value = {
      ...pagination,
      rowsNumber: pagedModels?.totalCount,
    };

    this.pagedModels.value = pagedModels as NonNullableAll<MPaged>;
  }

  /**
   * Updates the current list models
   * @param listModels The new list of models
   */
  updateList(listModels: MPagedItems[]) {
    this.listModels.value = listModels;
  }

  /**
   * Loads the paged models for a specific page
   * @param page The page number of the paged models to load
   * @param rowsPerPage The optional number of rows per page
   */
  @LoadingTrigger()
  async goToPage(page: number, rowsPerPage?: number) {
    await this.getPage({
      ...this.pagination.value,
      page,
      rowsPerPage,
    });
  }

  /**
   * Loads the next page of paged models
   */
  @LoadingTrigger()
  async nextPage() {
    if (this.pagination.value.page && !this.nextPageDisable.value) {
      await this.goToPage(this.pagination.value.page + 1);
    }
  }

  /**
   * Loads the previous page of paged models
   */
  @LoadingTrigger()
  async previousPage() {
    if (this.pagination.value.page && !this.previousPageDisable.value) {
      await this.goToPage(this.pagination.value.page - 1);
    }
  }

  /**
   * Gets a model with the given id, or sets the default model if no id is given.
   * @param id The optional id to use for getting a model
   */
  @LoadingTrigger()
  async getByIdOrDefault(id?: ModelIdType) {
    if (id) {
      await this.getById(id);
    } else {
      this.setDefault();
    }
  }

  /**
   * Gets a model by id and updates the store model
   * @param id The id of the model
   */
  @LoadingTrigger()
  async getById(id: ModelIdType | undefined) {
    if (id === undefined) {
      return;
    }

    const data = await this.getByIdFromApi(id);
    this.updateModel(data);
  }

  /**
   * Get a pages of models using the `pagination` properties for pagination
   */
  @LoadingTrigger()
  async getPage(pagination?: Pagination) {
    pagination = extend<Query>(
      true,
      {},
      this.defaultPagination.value,
      this.pagination.value,
      pagination
    );

    const query = this.buildQuery(pagination);
    const queryAndFilter = extend<Query>(true, {}, query, this.filter.value);

    // Only send query parameters with values
    const queryAndFilterWithValues = filterProps(
      queryAndFilter,
      (value) => !isValueEmpty(value)
    );

    const data = await this.getPageFromApi(queryAndFilterWithValues);
    this.updatePaged(data, pagination);
  }

  /**
   * Get a list of models
   * @param filter The optional filter to use
   */
  @LoadingTrigger()
  async getList(filter?: Indexable) {
    filter = extend<Query>(true, {}, this.filter.value, filter);
    const data = await this.getListFromApi(filter);
    this.updateList(data);
  }

  /**
   * Gets a value indicating if a give filter has a value
   * @param key The key of the filter to check
   * @returns `true` if the given filter has a value; otherwise, `false`
   */
  hasFilterValue(key: NestedKeyOf<Filter>) {
    return !isValueEmpty(this.filter.value[key]);
  }

  /**
   * Resets the current filter back to its default state
   */
  resetFilters() {
    this.filter.value = { ...this.defaultFilter.value };
  }

  /**
   * Updates a filter
   * @param key The key of the filter
   * @param value The value of the filter
   */
  updateFilter(key: NestedKeyOf<Filter>, value: any) {
    let updatedValue = value;

    // Get the correct cleared value for the target property
    if (updatedValue === undefined || updatedValue === null) {
      updatedValue = parseValue(value, {
        isArray: Array.isArray(this.filter.value[key]),
      });
    }

    this.filter.value = {
      ...this.filter.value,
      ...{ [key]: updatedValue },
    };
  }

  /**
   * Gets a model using an API
   * @param id The id of the model to get
   * @returns The model
   */
  protected abstract getByIdFromApi(id: ModelIdType): Promise<M>;

  /**
   * Gets pages of models using an API
   * @param query The query to get the models with
   * @returns Pages of models
   */
  protected abstract getPageFromApi(query: Query): Promise<MPaged>;

  /**
   * Gets a list of models using an API
   * @param query The query to get the models with
   * @returns List of models
   */
  protected abstract getListFromApi(query?: Indexable): Promise<MPagedItems[]>;
}

/**
 * A store that supports reading and paging objects with an API
 */
export class ReadableApiStore<
  API extends ReadableApi<M, MPaged, MPagedItems>,
  //
  // These types are typically set by developers
  //
  // Example: `ReadableStore<DeviceModel, DeviceNamePagedModel>`
  M extends ReadableModel = ReadableModel,
  MList extends ReadableModel = ReadableModel,
  MPaged extends PagedModel<MPagedItems> = PagedModel<any>,
  Filter extends object = any,
  //
  // These types are inferred and typically aren't set by developers
  //
  MPagedItems extends ReadableModel = ReadableModel,
  StoreSetup extends ReadableApiStoreSetup<
    API,
    M,
    MList,
    MPaged,
    Filter,
    MPagedItems
  > = ReadableApiStoreSetup<API, M, MList, MPaged, Filter, MPagedItems>
> extends BaseReadableApiStore<
  API,
  M,
  MList,
  MPaged,
  Filter,
  MPagedItems,
  StoreSetup
> {
  /**
   * Gets a model from an API
   * @param id The id of the model to get
   * @returns The model
   */
  protected async getByIdFromApi(id: ModelIdType) {
    if (!this.api.getById) {
      throw new Error('Method not implemented');
    }

    return await this.api.getById(id).then((response) => response.data);
  }

  /**
   * Gets pages of models from an API
   * @param query The query to get the models with
   * @returns Pages of models
   */
  protected async getPageFromApi(query: Query) {
    if (!this.api.getPage) {
      throw new Error('Method not implemented');
    }

    return await this.api.getPage(query).then((response) => response.data);
  }

  /**
   * Gets a list of models using an API
   * @param query The query to get the models with
   * @returns List of models
   */
  protected async getListFromApi(query: Indexable) {
    if (!this.api.getList) {
      throw new Error('Method not implemented');
    }

    return await this.api.getList(query).then((response) => response.data);
  }
}

/**
 * A store that supports reading and paging child objects with an API
 */
export class ReadableChildApiStore<
  //
  // These types are typically set by developers
  //
  API extends ReadableChildApi<M, MPaged, MPagedItems>,
  M extends ReadableModel = ReadableModel,
  MList extends ReadableModel = ReadableModel,
  MPaged extends PagedModel<MPagedItems> = PagedModel<any>,
  Filter extends object = any,
  //
  // These types are inferred and typically aren't set by developers
  //
  MPagedItems extends ReadableModel = ReadableModel,
  StoreSetup extends ReadableChildApiStoreSetup<
    API,
    M,
    MList,
    MPaged,
    Filter,
    MPagedItems
  > = ReadableChildApiStoreSetup<API, M, MList, MPaged, Filter, MPagedItems>
> extends BaseReadableApiStore<
  API,
  M,
  MList,
  MPaged,
  Filter,
  MPagedItems,
  StoreSetup
> {
  /**
   * The id of the parent model this model is a child of
   */
  parentId = ref<ModelIdType>(0);

  /**
   * The key of the parent id
   */
  parentIdKey = ref<NestedKeyOf<M>>();

  /**
   * Gets the key of the edit route parent parameter
   */
  parentRouteParamKey = ref<string>();

  /**
   * Initializes the store
   */
  init() {
    super.init();
    this.parentIdKey.value = this.storeSetup.parentIdModelKey;
    this.parentRouteParamKey.value = this.storeSetup.parentRouteParamKey;
  }

  /**
   * Gets the params for the create route
   */
  getCreateRouteParams() {
    const createRouteParams = super.getCreateRouteParams();

    if (this.parentRouteParamKey.value) {
      createRouteParams[this.parentRouteParamKey.value] = this.parentId.value;
    }

    return createRouteParams;
  }

  /**
   * Gets the route params for a given model
   * @param model The model to get the route params for
   * @returns The route params for the given model
   */
  getEditRouteParams(model: M | MPagedItems) {
    const editRouteParams = super.getEditRouteParams(model);

    if (this.parentRouteParamKey.value) {
      editRouteParams[this.parentRouteParamKey.value] = this.parentId.value;
    }

    return editRouteParams;
  }

  /**
   * Sets the current model to it's default state
   * @param value Optional default value
   */
  setDefault(value?: M) {
    const defaultValue = extend(true, {}, unref(this.defaultModel), value) as M;

    if (this.parentIdKey.value !== undefined) {
      // Automatically set the parent id when setting a new default value
      Reflect.setPath(
        defaultValue,
        this.parentIdKey.value,
        this.parentId.value
      );
    }

    super.setDefault(defaultValue);
  }

  /**
   * Gets a model using an API
   * @param id The id of the model to get
   * @returns The model
   */
  protected async getByIdFromApi(id: ModelIdType) {
    if (!this.api.getById) {
      throw new Error('Method not implemented');
    }

    return await this.api
      .getById(this.parentId.value, id)
      .then((response) => response.data);
  }

  /**
   * Gets pages of models using an API
   * @param query The query to get the models with
   * @returns Pages of models
   */
  protected async getPageFromApi(query: Query) {
    if (!this.api.getPage) {
      throw new Error('Method not implemented');
    }

    return await this.api
      .getPage(this.parentId.value, query)
      .then((response) => response.data);
  }

  /**
   * Gets a list of models using an API
   * @param query The query to get the models with
   * @returns List of models
   */
  protected async getListFromApi(query: Indexable) {
    if (!this.api.getList) {
      throw new Error('Method not implemented');
    }

    return await this.api
      .getList(this.parentId.value, query)
      .then((response) => response.data);
  }
}

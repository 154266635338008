/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BaseRule {
  type?: string | null;
}

export interface CalculationResult {
  value?: any;
  error?: string | null;
}

export enum CommentaryType {
  Unknown = "Unknown",
  Comment = "Comment",
  Resolved = "Resolved",
  Reviewed = "Reviewed",
  Approved = "Approved",
  Submitted = "Submitted",
}

export interface DeviceEditModel {
  name: string;
  manufacturer?: string | null;
  model?: string | null;
  station?: string | null;
  serialNumber?: string | null;
  status?: DeviceStatus;
  type?: DeviceType;
  locations?: DeviceLocationModel[] | null;
  extendedAttributes?: ExtendedAttributeModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceListItemModel {
  name?: string | null;
  serialNumber?: string | null;
  /** @format int32 */
  openLogCount?: number;
  station?: string | null;
  status?: DeviceStatus;
  type?: DeviceType;
  locations?: DeviceLocationModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceListItemPagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: DeviceListItemModel[] | null;
}

export interface DeviceLocationModel {
  location?: LocationNameModel;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export enum DeviceLogCategory {
  Unknown = "Unknown",
  DeviceErrorFault = "Device Error/Fault",
  ManufacturerBulletin = "Manufacturer Bulletin",
  PhysicsFollowUpServiceRequired = "Physics (follow-up service required)",
  PhysicsRoutine = "Physics (routine)",
  PhysicsNonRoutine = "Physics (non-routine",
  ServiceFollowUpPhysicsRequired = "Service (follow-up physics required)",
  ServiceRoutine = "Service (routine)",
  ServiceNonRoutine = "Service (non-routine)",
  Deficiency = "Deficiency",
  Recommendation = "Recommendation",
  AccreditationRenewal = "Accreditation Renewal",
  Safety = "Safety",
  TransducerDelamination = "Transducer Delamination",
}

export interface DeviceLogListItemModel {
  device: DeviceNameModel;
  location: LocationNameModel;
  status: DeviceLogStatus;
  category: DeviceLogCategory;
  /** @format date-time */
  logDateTime: string;
  title: string;
  /** @format int32 */
  noteCount?: number;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceLogListItemPagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: DeviceLogListItemModel[] | null;
}

export interface DeviceLogModel {
  device?: DeviceNameModel;
  location?: LocationNameModel;
  status?: DeviceLogStatus;
  category?: DeviceLogCategory;
  /** @format date-time */
  logDateTime?: string;
  title?: string | null;
  serviceTicket?: string | null;
  errorCode?: string | null;
  isReviewed?: boolean;
  /** @format date-time */
  outageStart?: string | null;
  /** @format date-time */
  outageEnd?: string | null;
  notes?: DeviceLogNoteListItemPagedModel;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceLogNoteListItemModel {
  comment?: string | null;
  files?: FileModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceLogNoteListItemPagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: DeviceLogNoteListItemModel[] | null;
}

export interface DeviceLogNoteModel {
  deviceLog?: DeviceLogModel;
  comment?: string | null;
  files?: FileModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceLogNotePagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: DeviceLogNoteModel[] | null;
}

export enum DeviceLogStatus {
  Unknown = "Unknown",
  Open = "Open",
  Waiting = "Waiting",
  Closed = "Closed",
}

export interface DeviceModel {
  name: string;
  manufacturer?: string | null;
  model?: string | null;
  station?: string | null;
  serialNumber?: string | null;
  status?: DeviceStatus;
  type?: DeviceType;
  locations?: DeviceLocationModel[] | null;
  extendedAttributes?: ExtendedAttributeModel[] | null;
  deviceLogs?: DeviceLogModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceNameModel {
  type?: DeviceType;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export enum DeviceStatus {
  Unknown = "Unknown",
  Active = "Active",
  Inactive = "Inactive",
}

export interface DeviceTestConfigListItemModel {
  name: string;
  /** @format int32 */
  order?: number;
  /** @format date-time */
  lastRun?: string | null;
  /** @format date-time */
  nextDue?: string;
  testTemplate?: NameModel;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestConfigModel {
  name: string;
  /** @format int32 */
  order?: number;
  testContext?: TestContext;
  testTemplate?: TestTemplateModel;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestResultCommentaryModel {
  comment?: string | null;
  commenter?: NameModel;
  /** @format date-time */
  commentDate?: string;
  commentaryType?: CommentaryType;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestResultListItemModel {
  /** @format int64 */
  deviceTestConfigId?: number;
  /** @format int32 */
  order?: number;
  /** @format date-time */
  testDate?: string;
  testStatus?: TestStatus;
  runBy?: NameModel;
  approvedBy?: NameModel;
  location?: LocationNameModel;
  test?: TestSetNameModel;
  schedule?: ScheduleNameModel;
  commentary?: DeviceTestResultCommentaryModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestResultListItemPagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: DeviceTestResultListItemModel[] | null;
}

export interface DeviceTestResultSaveModel {
  /** @format int64 */
  deviceTestConfigId?: number;
  /** @format date-time */
  testDate?: string;
  note?: string | null;
  testContext?: TestContext;
  testStatus?: TestStatus;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestResultStatusModel {
  testResultStatus?: TestResultStatus;
  comment?: string | null;
  testResultIds?: number[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestRunModel {
  device?: DeviceNameModel;
  locations?: LocationNameModel[] | null;
  tests?: DeviceTestResultListItemModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestRunSaveModel {
  device?: DeviceNameModel;
  location?: LocationNameModel;
  runBy?: NameModel;
  tests?: DeviceTestResultSaveModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestRunTestModel {
  testSetName?: TestSetNameModel;
  testTemplate?: TestTemplateModel;
  testContext?: TestContext;
  /** @format int64 */
  deviceTestConfigId?: number;
  /** @format int32 */
  order?: number;
  /** @format date-time */
  testDate?: string;
  testStatus?: TestStatus;
  runBy?: NameModel;
  approvedBy?: NameModel;
  location?: LocationNameModel;
  test?: TestSetNameModel;
  schedule?: ScheduleNameModel;
  commentary?: DeviceTestResultCommentaryModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestSetConfigListItemModel {
  name: string;
  /** @format int32 */
  order?: number;
  schedule?: ScheduleListItemModel;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string | null;
  configuredTests?: DeviceTestConfigListItemModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestSetConfigListItemPagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: DeviceTestSetConfigListItemModel[] | null;
}

export interface DeviceTestSetConfigModel {
  name: string;
  /** @format int32 */
  order?: number;
  device?: DeviceNameModel;
  schedule?: ScheduleNameModel;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string | null;
  hasDeviceTestConfigsChanges?: boolean;
  testSet?: NameModel;
  deviceTestConfigs?: DeviceTestConfigModel[] | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface DeviceTestSetResultListModel {
  testSet?: NameModel;
  testResults?: DeviceTestResultListItemPagedModel;
}

export enum DeviceType {
  Unknown = "Unknown",
  AnalyticalXRay = "Analytical X-Ray",
  Barometer = "Barometer",
  BoneDensitometryUnit = "Bone Densitometry Unit",
  CabinetXRay = "Cabinet X-Ray",
  CodeCrashCart = "Code/Crash Cart",
  CT = "CT",
  DailyQADevice = "Daily QA Device",
  Dental = "Dental",
  Densitometer = "Densitometer",
  DiagnosticReviewWorkstation = "Diagnostic Review Workstation",
  DICOMPrinter = "DICOM Printer",
  DigitalRadiography = "Digital Radiography",
  DoseCalibrator = "Dose Calibrator",
  Electrometer = "Electrometer",
  ElectronMicroscope = "Electron Microscope",
  ElectronicMedicalRecordSystem = "Electronic Medical Record System",
  Fluoroscopy = "Fluoroscopy",
  GammaCamera = "Gamma Camera",
  Generic = "Generic",
  IMRTQADevice = "IMRT QA Device",
  InVivoDosimetrySystem = "In-Vivo Dosimetry system",
  IonChamber = "Ion Chamber",
  LinearAccelerator = "Linear Accelerator",
  Mammography = "Mammography",
  MUCalculationSoftware = "MU Calculation Software",
  ParticleAccelerator = "Particle Accelerator",
  PersonnelMonitoringEquipment = "Personnel Monitoring Equipment",
  PETCT = "PET CT",
  Phantom = "Phantom",
  SpecializedSetupEquipment = "Specialized Setup Equipment",
  SPECTCT = "SPECT/CT",
  SPECT = "SPECT",
  StereotacticBreastBiopsy = "Stereotactic Breast Biopsy",
  SurveyMeter = "Survey Meter",
  Thermometer = "Thermometer",
  ThyroidProbe = "Thyroid Probe",
  Tomosynthesis = "Tomosynthesis",
  TreatmentPlanningSystem = "Treatment Planning System",
  MRI = "MRI",
  Ultrasound = "Ultrasound",
  UltrasoundProbe = "Ultrasound Probe",
  XRayIrradiator = "X-Ray Irradiator",
  Xenon = "Xenon",
  WellChamber = "Well Chamber",
  WellCounter = "Well Counter",
  GammaCounter = "Gamma Counter",
  ProtectiveAprons = "Protective Aprons",
  GammaKnife = "Gamma Knife",
  Brachytherapy = "Brachytherapy",
}

export interface ExtendedAttributeModel {
  name: string;
  value?: string | null;
  /** @format int32 */
  order?: number;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface FileModel {
  /** @format uuid */
  storageId?: string;
  name?: string | null;
  fileType?: string | null;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export enum Frequency {
  Unknown = "Unknown",
  Daily7Days = "Daily (7 Days)",
  DailyMonFri = "Daily (Mon-Fri)",
  Biweekly = "Biweekly",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Semiannual = "Semiannual",
  Yearly = "Yearly",
}

export enum HttpStatusCode {
  Continue = "Continue",
  SwitchingProtocols = "SwitchingProtocols",
  Processing = "Processing",
  EarlyHints = "EarlyHints",
  OK = "OK",
  Created = "Created",
  Accepted = "Accepted",
  NonAuthoritativeInformation = "NonAuthoritativeInformation",
  NoContent = "NoContent",
  ResetContent = "ResetContent",
  PartialContent = "PartialContent",
  MultiStatus = "MultiStatus",
  AlreadyReported = "AlreadyReported",
  IMUsed = "IMUsed",
  Ambiguous = "Ambiguous",
  Moved = "Moved",
  Redirect = "Redirect",
  RedirectMethod = "RedirectMethod",
  NotModified = "NotModified",
  UseProxy = "UseProxy",
  Unused = "Unused",
  TemporaryRedirect = "TemporaryRedirect",
  PermanentRedirect = "PermanentRedirect",
  BadRequest = "BadRequest",
  Unauthorized = "Unauthorized",
  PaymentRequired = "PaymentRequired",
  Forbidden = "Forbidden",
  NotFound = "NotFound",
  MethodNotAllowed = "MethodNotAllowed",
  NotAcceptable = "NotAcceptable",
  ProxyAuthenticationRequired = "ProxyAuthenticationRequired",
  RequestTimeout = "RequestTimeout",
  Conflict = "Conflict",
  Gone = "Gone",
  LengthRequired = "LengthRequired",
  PreconditionFailed = "PreconditionFailed",
  TooLarge = "RequestEntityTooLarge",
  RequestUriTooLong = "RequestUriTooLong",
  UnsupportedMediaType = "UnsupportedMediaType",
  RequestedRangeNotSatisfiable = "RequestedRangeNotSatisfiable",
  ExpectationFailed = "ExpectationFailed",
  MisdirectedRequest = "MisdirectedRequest",
  UnprocessableEntity = "UnprocessableEntity",
  Locked = "Locked",
  FailedDependency = "FailedDependency",
  UpgradeRequired = "UpgradeRequired",
  PreconditionRequired = "PreconditionRequired",
  TooManyRequests = "TooManyRequests",
  RequestHeaderFieldsTooLarge = "RequestHeaderFieldsTooLarge",
  UnavailableForLegalReasons = "UnavailableForLegalReasons",
  InternalServerError = "InternalServerError",
  NotImplemented = "NotImplemented",
  BadGateway = "BadGateway",
  ServiceUnavailable = "ServiceUnavailable",
  GatewayTimeout = "GatewayTimeout",
  HttpVersionNotSupported = "HttpVersionNotSupported",
  VariantAlsoNegotiates = "VariantAlsoNegotiates",
  InsufficientStorage = "InsufficientStorage",
  LoopDetected = "LoopDetected",
  NotExtended = "NotExtended",
  NetworkAuthenticationRequired = "NetworkAuthenticationRequired",
}

export interface ITestSection {
  fields?: TestFieldBase[] | null;
  type?: string | null;
}

export interface LocationModel {
  name?: string | null;
  type?: LocationType;
  timeZone?: TimeZoneModel;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface LocationNameModel {
  type?: LocationType;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface LocationNamePagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: LocationNameModel[] | null;
}

export interface LocationNameTreeModel {
  name: string;
  type?: LocationType;
  children?: LocationNameTreeModel[] | null;
  /** @format int64 */
  id?: number;
}

export enum LocationType {
  Unknown = "Unknown",
  Organization = "Organization",
  Facility = "Facility",
  Department = "Department",
  Room = "Room",
  Station = "Station",
  Location = "Location",
  Other = "Other",
}

export interface NameModel {
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface QADeviceModel {
  device?: DeviceNameModel;
  /** @format int32 */
  unresolvedLogCount?: number;
  /** @format int32 */
  unresolvedTestCount?: number;
  locations?: LocationNameModel[] | null;
  tests?: QATestModel[] | null;
  type?: DeviceType;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface QADevicePagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: QADeviceModel[] | null;
}

export interface QATestModel {
  frequency?: Frequency;
  ids?: number[] | null;
}

export interface ScheduleListItemModel {
  name: string;
  frequency?: Frequency;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

export interface ScheduleListItemPagedModel {
  /** @format int64 */
  totalCount?: number;
  /** @format int64 */
  totalPages?: number;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  pageSize?: number;
  items?: ScheduleListItemModel[] | null;
}

export interface ScheduleNameModel {
  frequency?: Frequency;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface TestCalculcation {
  name?: string | null;
  /** @format int32 */
  order?: number;
  calculcation?: string | null;
}

export interface TestContext {
  /** @format int64 */
  deviceTestConfigId?: number;
  /** @format int64 */
  testResultId?: number;
  setupContext?: Record<string, any>;
  inputContext?: Record<string, any>;
  calculationContext?: Record<string, CalculationResult>;
}

export interface TestFieldBase {
  name: string;
  label: string;
  defaultValue?: string | null;
  hidden?: boolean | null;
  disabled?: boolean | null;
  required?: boolean | null;
  condition?: BaseRule;
  type?: string | null;
}

export enum TestResultStatus {
  Unknown = "Unknown",
  Excluded = "Excluded",
  Resolved = "Resolved",
  Approved = "Approved",
}

export interface TestSchema {
  sections?: ITestSection[] | null;
  type?: string | null;
}

export interface TestSetNameModel {
  /** @format int64 */
  testSetId?: number;
  testSetName?: string | null;
  /** @format int64 */
  testId?: number;
  testName?: string | null;
  /** @format int64 */
  templateId?: number;
  templateName?: string | null;
  displayName?: string | null;
}

export interface TestSetupSchema {
  requiredDeviceAttributes?: string[] | null;
  sections?: ITestSection[] | null;
  type?: string | null;
}

export enum TestStatus {
  Unknown = "Unknown",
  Incomplete = "Incomplete",
  Pass = "Pass",
  Fail = "Fail",
  Skipped = "Skipped",
  Excluded = "Excluded",
  Resolved = "Resolved",
}

export interface TestTemplateModel {
  templateSchema?: TestTemplateSchema;
  name?: string | null;
  /** @format int64 */
  id?: number;
}

export interface TestTemplateSchema {
  name?: string | null;
  setup?: TestSetupSchema;
  test?: TestSchema;
  calculations?: TestCalculcation[] | null;
  type?: string | null;
}

export interface TimeZoneModel {
  standardName?: string | null;
  displayName?: string | null;
  /** @format double */
  baseUtcOffset?: number;
  /** @format date-time */
  changedAt?: string;
  /** @format int64 */
  changedById?: number;
  changedBy?: string | null;
  deleted?: boolean | null;
  /** @format int64 */
  id?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/devices" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: Iterable<any> = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Enzee.Backend.Device.Api
 * @version 1.0
 * @baseUrl /devices
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  device = {
    /**
     * @description Gets a list of Enzee.Backend.Device.Api.v1.Models.DeviceNameModels
     *
     * @tags Device
     * @name GetNamesList
     * @summary Gets a list of Enzee.Backend.Device.Api.v1.Models.DeviceNameModels
     * @request GET:/names
     */
    getNamesList: (
      query?: {
        LocationIds?: number[];
        Statuses?: DeviceStatus[];
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceNameModel[], any>({
        path: `/names`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a Enzee.Backend.Core.Api.Models.PagedModel`1
     *
     * @tags Device
     * @name GetPage
     * @summary Gets a Enzee.Backend.Core.Api.Models.PagedModel`1
     * @request GET:/
     */
    getPage: (
      query?: {
        LocationIds?: number[];
        Statuses?: DeviceStatus[];
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<QADevicePagedModel, any>({
        path: `/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Save a Enzee.Backend.Device.Api.v1.Models.DeviceModel. If model isn't valid, returns bad request.  If data service doesn't return an object after save, returns 500.  Else returns the saved object, with id(s) populated.
     *
     * @tags Device
     * @name Save
     * @summary Save a Enzee.Backend.Device.Api.v1.Models.DeviceModel. If model isn't valid, returns bad request.  If data service doesn't return an object after save, returns 500.  Else returns the saved object, with id(s) populated.
     * @request POST:/
     */
    save: (data: DeviceEditModel, params: RequestParams = {}) =>
      this.request<DeviceEditModel, any>({
        path: `/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a Device name and returns true if it is unique, returns false if it is not.
     *
     * @tags Device
     * @name GetUniquename
     * @summary Takes a Device name and returns true if it is unique, returns false if it is not.
     * @request GET:/uniquename
     */
    getUniquename: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/uniquename`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a list of Enzee.Backend.Device.Api.v1.Models.DeviceListItemModels
     *
     * @tags Device
     * @name GetListListPage
     * @summary Gets a list of Enzee.Backend.Device.Api.v1.Models.DeviceListItemModels
     * @request GET:/list
     */
    getListListPage: (
      query?: {
        DeviceName?: string;
        SerialNumber?: string;
        Station?: string;
        DeviceTypes?: DeviceType[];
        LocationIds?: number[];
        Statuses?: DeviceStatus[];
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceListItemPagedModel, any>({
        path: `/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a Enzee.Backend.Device.Api.v1.Models.DeviceModel by ID Returns the item or not found
     *
     * @tags Device
     * @name GetById
     * @summary Get a Enzee.Backend.Device.Api.v1.Models.DeviceModel by ID Returns the item or not found
     * @request GET:/{id}
     */
    getById: (id: number, params: RequestParams = {}) =>
      this.request<DeviceModel, any>({
        path: `/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a Enzee.Backend.Device.Api.v1.Models.DeviceEditModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     *
     * @tags Device
     * @name Delete
     * @summary Delete a Enzee.Backend.Device.Api.v1.Models.DeviceEditModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     * @request DELETE:/{id}
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<DeviceEditModel, any>({
        path: `/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * @description Get a Enzee.Backend.Device.Api.v1.Models.DeviceEditModel by ID Returns the item or not found
     *
     * @tags Device
     * @name GetEdit
     * @summary Get a Enzee.Backend.Device.Api.v1.Models.DeviceEditModel by ID Returns the item or not found
     * @request GET:/{id}/edit
     */
    getEdit: (id: number, params: RequestParams = {}) =>
      this.request<DeviceEditModel, any>({
        path: `/${id}/edit`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  deviceLog = {
    /**
     * @description Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.DeviceLogListItemModels
     *
     * @tags DeviceLog
     * @name GetPage
     * @summary Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.DeviceLogListItemModels
     * @request GET:/{deviceId}/logs
     */
    getPage: (
      deviceId: number,
      query?: {
        Title?: string;
        Statuses?: DeviceLogStatus[];
        Categories?: DeviceLogCategory[];
        Locations?: number[];
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceLogListItemPagedModel, any>({
        path: `/${deviceId}/logs`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Save a Enzee.Backend.Device.Api.v1.Models.DeviceLogModel. If model isn't valid, returns bad request If data service doesn't return an object after save, returns 500 else returns the saved object, typically with new id(s)
     *
     * @tags DeviceLog
     * @name Save
     * @summary Save a Enzee.Backend.Device.Api.v1.Models.DeviceLogModel. If model isn't valid, returns bad request If data service doesn't return an object after save, returns 500 else returns the saved object, typically with new id(s)
     * @request POST:/{deviceId}/logs
     */
    save: (deviceId: number, data: DeviceLogModel, params: RequestParams = {}) =>
      this.request<DeviceLogModel, any>({
        path: `/${deviceId}/logs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a Enzee.Backend.Device.Api.v1.Models.DeviceLogModel by ID Returns the item or not found
     *
     * @tags DeviceLog
     * @name GetById
     * @summary Get a Enzee.Backend.Device.Api.v1.Models.DeviceLogModel by ID Returns the item or not found
     * @request GET:/{deviceId}/logs/{logId}
     */
    getById: (deviceId: number, logId: number, params: RequestParams = {}) =>
      this.request<DeviceLogModel, any>({
        path: `/${deviceId}/logs/${logId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a Enzee.Backend.Device.Api.v1.Models.DeviceLogModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     *
     * @tags DeviceLog
     * @name Delete
     * @summary Delete a Enzee.Backend.Device.Api.v1.Models.DeviceLogModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     * @request DELETE:/{deviceId}/logs/{logId}
     */
    delete: (deviceId: number, logId: number, params: RequestParams = {}) =>
      this.request<DeviceLogModel, any>({
        path: `/${deviceId}/logs/${logId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  deviceLogNote = {
    /**
     * @description Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel
     *
     * @tags DeviceLogNote
     * @name GetPage
     * @summary Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel
     * @request GET:/{deviceId}/logs/{logId}/notes
     */
    getPage: (
      deviceId: number,
      logId: number,
      query?: {
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceLogNotePagedModel, any>({
        path: `/${deviceId}/logs/${logId}/notes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Save a Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel If model isn't valid, returns bad request If data service doesn't return an object after save, returns 500 else returns the saved object, typically with new id(s)
     *
     * @tags DeviceLogNote
     * @name Save
     * @summary Save a Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel If model isn't valid, returns bad request If data service doesn't return an object after save, returns 500 else returns the saved object, typically with new id(s)
     * @request POST:/{deviceId}/logs/{logId}/notes
     */
    save: (deviceId: number, logId: number, data: DeviceLogNoteModel, params: RequestParams = {}) =>
      this.request<DeviceLogNoteModel, any>({
        path: `/${deviceId}/logs/${logId}/notes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel by ID Returns the item or not found
     *
     * @tags DeviceLogNote
     * @name GetById
     * @summary Get a Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel by ID Returns the item or not found
     * @request GET:/{deviceId}/logs/{logId}/notes/{noteId}
     */
    getById: (deviceId: number, logId: number, noteId: number, params: RequestParams = {}) =>
      this.request<DeviceLogNoteModel, any>({
        path: `/${deviceId}/logs/${logId}/notes/${noteId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     *
     * @tags DeviceLogNote
     * @name Delete
     * @summary Delete a Enzee.Backend.Device.Api.v1.Models.DeviceLogNoteModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     * @request DELETE:/{deviceId}/logs/{logId}/notes/{noteId}
     */
    delete: (deviceId: number, logId: number, noteId: number, params: RequestParams = {}) =>
      this.request<DeviceLogNoteModel, any>({
        path: `/${deviceId}/logs/${logId}/notes/${noteId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  deviceTest = {
    /**
     * @description Takes a deviceId and an array of configTestIds and returns a Enzee.Backend.Device.Api.v1.Models.DeviceTestRunModel. If Device Id doesn't exists returns NotFound. If DeviceTestConfig Ids don't exists throws ArgumentException.
     *
     * @tags DeviceTest
     * @name GetRun
     * @summary Takes a deviceId and an array of configTestIds and returns a Enzee.Backend.Device.Api.v1.Models.DeviceTestRunModel. If Device Id doesn't exists returns NotFound. If DeviceTestConfig Ids don't exists throws ArgumentException.
     * @request GET:/{deviceId}/configured-tests/run
     */
    getRun: (
      deviceId: number,
      query?: {
        configTestId?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceTestRunModel, any>({
        path: `/${deviceId}/configured-tests/run`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Saves a Enzee.Backend.Device.Api.v1.Models.DeviceTestRunSaveModel May return Microsoft.AspNetCore.Mvc.BadRequestObjectResult with any model errors
     *
     * @tags DeviceTest
     * @name Save
     * @summary Saves a Enzee.Backend.Device.Api.v1.Models.DeviceTestRunSaveModel May return Microsoft.AspNetCore.Mvc.BadRequestObjectResult with any model errors
     * @request POST:/{deviceId}/configured-tests/run
     */
    save: (deviceId: number, data: DeviceTestRunSaveModel, params: RequestParams = {}) =>
      this.request<DeviceTestRunSaveModel, any>({
        path: `/${deviceId}/configured-tests/run`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetResultListModel by deviceTestSetConfigId If Id doesn't exist in Db returns NotFound.
     *
     * @tags DeviceTest
     * @name GetById
     * @summary Gets a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetResultListModel by deviceTestSetConfigId If Id doesn't exist in Db returns NotFound.
     * @request GET:/{deviceId}/test-results/{deviceTestSetConfigId}
     */
    getById: (
      deviceId: number,
      deviceTestSetConfigId: number,
      query?: {
        RunByName?: string;
        TestName?: string;
        LocationIds?: number[];
        TestStatuses?: TestStatus[];
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceTestSetResultListModel, any>({
        path: `/${deviceId}/test-results/${deviceTestSetConfigId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Saves a Enzee.Backend.Device.Api.v1.Models.DeviceTestResultStatusModel
     *
     * @tags DeviceTest
     * @name SaveStatusCreate
     * @summary Saves a Enzee.Backend.Device.Api.v1.Models.DeviceTestResultStatusModel
     * @request POST:/{deviceId}/test-results/status
     */
    saveStatusCreate: (deviceId: number, data: DeviceTestResultStatusModel, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/${deviceId}/test-results/status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a Enzee.Backend.Device.Api.v1.Models.DeviceTestRunTestModel by deviceTestConfigId. May return NotFound.
     *
     * @tags DeviceTest
     * @name GetGetDeviceTestById
     * @summary Gets a Enzee.Backend.Device.Api.v1.Models.DeviceTestRunTestModel by deviceTestConfigId. May return NotFound.
     * @request GET:/{deviceId}/configured-tests/run/{deviceTestConfigId}/{testResultId}
     */
    getGetDeviceTestById: (
      deviceId: number,
      deviceTestConfigId: number,
      testResultId: number,
      params: RequestParams = {},
    ) =>
      this.request<DeviceTestRunTestModel, any>({
        path: `/${deviceId}/configured-tests/run/${deviceTestConfigId}/${testResultId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  deviceTestSetConfig = {
    /**
     * @description Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigListItemModels
     *
     * @tags DeviceTestSetConfig
     * @name GetPage
     * @summary Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigListItemModels
     * @request GET:/{deviceId}/configured-tests
     */
    getPage: (
      deviceId: number,
      query?: {
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceTestSetConfigListItemPagedModel, any>({
        path: `/${deviceId}/configured-tests`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Save a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigModel. If model isn't valid, returns bad request If data service doesn't return an object after save, returns 500 else returns the saved object, typically with new id(s)
     *
     * @tags DeviceTestSetConfig
     * @name Save
     * @summary Save a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigModel. If model isn't valid, returns bad request If data service doesn't return an object after save, returns 500 else returns the saved object, typically with new id(s)
     * @request POST:/{deviceId}/configured-tests
     */
    save: (deviceId: number, data: DeviceTestSetConfigModel, params: RequestParams = {}) =>
      this.request<DeviceTestSetConfigModel, any>({
        path: `/${deviceId}/configured-tests`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigModel by deviceTestSetConfigId if ID exists in Db If ID doesn't exist returns a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigModel by deviceId, testTemplateIds and testSetId.
     *
     * @tags DeviceTestSetConfig
     * @name GetById
     * @summary Get a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigModel by deviceTestSetConfigId if ID exists in Db If ID doesn't exist returns a Enzee.Backend.Device.Api.v1.Models.DeviceTestSetConfigModel by deviceId, testTemplateIds and testSetId.
     * @request GET:/{deviceId}/configured-tests/{deviceTestSetConfigId}
     */
    getById: (
      deviceId: number,
      deviceTestSetConfigId: number,
      query?: {
        testTemplateIds?: number[];
        /** @format int64 */
        testSetId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceTestSetConfigModel, any>({
        path: `/${deviceId}/configured-tests/${deviceTestSetConfigId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  location = {
    /**
     * @description Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.LocationModels
     *
     * @tags Location
     * @name GetPage
     * @summary Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.LocationModels
     * @request GET:/location
     */
    getPage: (
      query?: {
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<LocationNamePagedModel, any>({
        path: `/location`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Save a Enzee.Backend.Device.Api.v1.Models.LocationModel. If model isn't valid, returns bad request.  If data service doesn't return an object after save, returns 500.  else returns the saved object, typically with new id(s)
     *
     * @tags Location
     * @name Save
     * @summary Save a Enzee.Backend.Device.Api.v1.Models.LocationModel. If model isn't valid, returns bad request.  If data service doesn't return an object after save, returns 500.  else returns the saved object, typically with new id(s)
     * @request POST:/location
     */
    save: (data: LocationModel, params: RequestParams = {}) =>
      this.request<LocationModel, any>({
        path: `/location`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a Enzee.Backend.Device.Api.v1.Models.LocationModel by ID Returns the item or not found
     *
     * @tags Location
     * @name GetById
     * @summary Get a Enzee.Backend.Device.Api.v1.Models.LocationModel by ID Returns the item or not found
     * @request GET:/location/{id}
     */
    getById: (id: number, params: RequestParams = {}) =>
      this.request<LocationModel, any>({
        path: `/location/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a Enzee.Backend.Device.Api.v1.Models.LocationModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     *
     * @tags Location
     * @name Delete
     * @summary Delete a Enzee.Backend.Device.Api.v1.Models.LocationModel by id. If model isn't valid, returns bad request If data service doesn't find the object, returns 404 else returns the object data that was just deleted
     * @request DELETE:/location/{id}
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<LocationModel, any>({
        path: `/location/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * @description Gets a list of Enzee.Backend.Device.Api.v1.Models.LocationNameTreeModels. Node Id of 0 will return root nodes and all children.
     *
     * @tags Location
     * @name GetTreeById
     * @summary Gets a list of Enzee.Backend.Device.Api.v1.Models.LocationNameTreeModels. Node Id of 0 will return root nodes and all children.
     * @request GET:/location/tree/{node}
     */
    getTreeById: (node: number, params: RequestParams = {}) =>
      this.request<LocationNameTreeModel[], any>({
        path: `/location/tree/${node}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  ready = {
    /**
     * No description
     *
     * @tags Ready
     * @name Get
     * @request GET:/ready
     */
    get: (
      query?: {
        refresh?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, string>, any>({
        path: `/ready`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ready
     * @name GetEcho
     * @request GET:/echo
     */
    getEcho: (
      query?: {
        status?: HttpStatusCode;
        msg?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/echo`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  schedule = {
    /**
     * @description Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.ScheduleListItemModels
     *
     * @tags Schedule
     * @name GetPage
     * @summary Gets a Paged list of Enzee.Backend.Device.Api.v1.Models.ScheduleListItemModels
     * @request GET:/schedule
     */
    getPage: (
      query?: {
        /** @format int32 */
        Page?: number;
        /** @format int32 */
        Size?: number;
        Sort?: string;
        Descending?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ScheduleListItemPagedModel, any>({
        path: `/schedule`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description Gets a list of user Enzee.Backend.Core.Models.NameModels
     *
     * @tags User
     * @name GetList
     * @summary Gets a list of user Enzee.Backend.Core.Models.NameModels
     * @request GET:/user/list
     */
    getList: (params: RequestParams = {}) =>
      this.request<NameModel[], any>({
        path: `/user/list`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}

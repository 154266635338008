import { resolveUnref } from '@vueuse/core';
import {
  BaseModel,
  ExtendedAttributeType,
  Indexable,
  NonNullableAll,
  ReadableModel,
  SupportsExtendedAttributes,
} from 'models/base';
import { GridColumn } from 'models/grid';
import { PagedModel } from 'models/paged';
import { Pagination } from 'models/pagination';
import { ModelStoreSetup } from 'models/store';
import { extend } from 'quasar';
import { useExtendedAttribute } from 'shared/composables';
import { filterProps, isComplexType, isPrimitiveType } from 'shared/utility';
import { computed, isRef, ref, Ref, unref } from 'vue';
import { BaseStore } from './base';

/**
 * A class for all stores that use models
 */
export class ModelStore<
  M extends BaseModel = BaseModel,
  MList extends ReadableModel = ReadableModel,
  MPaged extends PagedModel<MPagedItems> = PagedModel<any>,
  Filter extends object = any,
  //
  // These types are inferred and typically aren't set by developers
  //
  MPagedItems extends ReadableModel = ReadableModel,
  StoreSetup extends ModelStoreSetup<
    M,
    MList,
    MPaged,
    Filter,
    MPagedItems
  > = ModelStoreSetup<M, MList, MPaged, Filter, MPagedItems>
> extends BaseStore<StoreSetup> {
  /**
   * Initializes the store
   */
  init() {
    super.init();

    this.defaultModel.value = (this.storeSetup.defaultModel ||
      {}) as NonNullableAll<M>;

    this.modelCustomProps.value = this.getCustomModelProps();
    this.model.value = (this.storeSetup.model || {}) as NonNullableAll<M>;

    this.addCustomPropsToModel();
    this.snapshot();

    this.defaultFilter.value = { ...this.storeSetup.filter } || {};
    this.filter.value = { ...this.storeSetup.filter } || {};

    this.defaultPagination.value = {
      ...this.defaultPagination.value,
      ...this.storeSetup.pagination,
    };
    this.pagination.value = this.defaultPagination.value;
  }

  /**
   * The previous model in the store
   */
  previousModel = ref({}) as Ref<NonNullableAll<M>>;

  /**
   * The default model in the store
   */
  defaultModel = ref({}) as Ref<NonNullableAll<M>>;

  /**
   * The optional custom properties added to the model
   */
  modelCustomProps = ref<Indexable>({});

  /**
   * Gets the names of the optional custom model properties
   */
  modelCustomPropNames = computed(() =>
    Object.keys(this.modelCustomProps.value)
  );

  /**
   * The current model in the store
   */
  model = ref({}) as Ref<NonNullableAll<M>>;

  /**
   * Indicates if the current model is new
   */
  isModelNew = computed(
    () => this.model.value && this.model.value.id === undefined
  );

  /**
   * Gets the names of the extended attributes
   */
  extendedAttributeNames = computed(
    () => (this.storeSetup.extendedAttributes as string[]) || []
  );

  /**
   * Gets the names of auditable properties
   */
  auditablePropNames = computed(() => [
    'createdAt',
    'createdBy',
    'createdById',
    'changedAt',
    'changedBy',
    'changedById',
    'updatedAt',
    'updatedBy',
    'updatedById',
    'deletedAt',
    'deletedBy',
    'deletedById',
  ]);

  /**
   * The current list of paged models in the store
   */
  pagedModels = ref({}) as Ref<NonNullableAll<MPaged>>;

  /**
   * The current page of the paged models
   */
  page = computed(() => this.pagedModels.value?.page || 0);

  /**
   * The current total number of paged models
   */
  totalCount = computed(() => this.pagedModels.value?.totalCount || 0);

  /**
   * The current total number of pages of paged models
   */
  pageCount = computed(() => this.pagedModels.value?.totalPages || 0);

  /**
   * The current list of models in the store
   */
  listModels = ref([]) as Ref<NonNullableAll<MPagedItems[]>>;

  /**
   * The default value of the pagination
   */
  defaultPagination = ref<Pagination>({
    page: 1,
    rowsPerPage: 25,
    sortBy: undefined,
    descending: undefined,
    rowsNumber: undefined,
  });

  /**
   * The current pagination for the list of models which is usually used by grids
   */
  pagination = ref<Pagination>({});

  /**
   * The default filter value used when `resetFilter` is called
   */
  defaultFilter = ref<Filter>({} as Filter);

  /**
   * The current filter for loading the paged model
   */
  filter = ref<Filter>({} as Filter);

  /**
   * Gets the total number of pages
   */
  totalPages = computed(() => {
    if (!this.pagination.value.rowsPerPage) {
      return 0;
    }

    if (!this.pagination.value.rowsNumber) {
      return 0;
    }

    const total =
      this.pagination.value.rowsNumber / this.pagination.value.rowsPerPage;

    return Math.ceil(total);
  });

  /**
   * Indicates if the previous page is enabled
   */
  previousPageDisable = computed(
    () => !this.pagination.value.page || this.pagination.value.page === 1
  );

  /**
   * Indicates if the next page is enabled
   */
  nextPageDisable = computed(
    () =>
      !this.pagination.value.page ||
      this.pagination.value.page === this.totalPages.value
  );

  /**
   * The columns to use for showing the models in a grid
   */
  columns = ref<GridColumn[]>();

  /**
   * Sets the current model to it's default state
   * @param value Optional default value
   */
  setDefault(value?: M) {
    const updatedModel = { ...this.defaultModel.value, ...value };
    this.updateModel(updatedModel);
  }

  /**
   * Takes a snapshot of the current model so it can be restored by calling `reset()`
   */
  snapshot() {
    this.previousModel.value = extend(true, {}, unref(this.model));

    // We want to include `undefined` properties because `Object.hasOwn` is used throughout the app
    Object.keys(this.model.value).forEach((key) => {
      if (this.model.value[key] === undefined) {
        (this.previousModel.value as Indexable)[key] = undefined;
      }
    });
  }

  /**
   * Resets the current model back to its previous value
   */
  reset() {
    this.updateModel(this.previousModel.value);
  }

  /**
   * Gets custom model properties to use. This is called before every model update.
   */
  getCustomModelProps() {
    const extendedAttributeProps: Indexable = {};

    this.extendedAttributeNames.value.forEach((name) => {
      const attribute = (
        this.model.value as SupportsExtendedAttributes
      ).extendedAttributes?.find((attribute) => attribute.name === name);

      extendedAttributeProps[name] = useExtendedAttribute(
        this.model,
        name,
        attribute?.value as ExtendedAttributeType
      );
    });

    return extendedAttributeProps;
  }

  /**
   * Gets a shallow copy of the model without the custom properties
   * @param model The optional model to get without custom properties. If this
   * value is not set, then the current model is used.
   */
  getModelWithoutCustomProps(model?: Indexable | undefined) {
    model = model || this.model.value;

    return filterProps(model, (value, key) => {
      const isCustomModelProp =
        this.modelCustomPropNames.value.indexOf(String(key)) >= 0;

      return !isCustomModelProp;
    });
  }

  /**
   * Gets a shallow copy of the model without the auditable properties
   * @param model The optional model to get without auditable properties. If this
   * value is not set, then the current model is used.
   */
  getModelWithoutAuditableProps(model?: Indexable | undefined) {
    const result: Indexable = {};
    model = model || this.model.value;

    if (isPrimitiveType(model)) {
      return model;
    }

    if (!model) {
      return result;
    }

    // Recursively strip out auditable properties
    Object.keys(model).forEach((key) => {
      if (this.auditablePropNames.value.includes(key)) {
        return;
      }

      if (Array.isArray(model?.[key])) {
        result[key] = [];

        model?.[key].forEach((item: any) => {
          const itemResult = this.getModelWithoutAuditableProps(item);
          result[key].push(itemResult);
        });
      } else if (isComplexType(model?.[key])) {
        result[key] = this.getModelWithoutAuditableProps(model?.[key]);
      } else {
        result[key] = model?.[key];
      }
    });

    return result;
  }

  /**
   * Updates the current model and takes a snapshot of the new model
   * @param model The new model
   */
  updateModel(model: Partial<M>) {
    this.model.value = model as M;
    this.modelCustomProps.value = this.getCustomModelProps();

    this.addCustomPropsToModel();
    this.snapshot();
  }

  /**
   * Adds any defined custom model properties to the model
   */
  addCustomPropsToModel() {
    Object.keys(this.modelCustomProps.value).forEach((key) => {
      (this.model.value as Indexable)[key] = computed({
        get: () => resolveUnref(this.modelCustomProps.value[key]),
        set: (value) => {
          if (isRef(this.modelCustomProps.value[key])) {
            this.modelCustomProps.value[key].value = value;
          } else {
            this.modelCustomProps.value[key] = value;
          }
        },
      });
    });
  }
}

import { BaseValidation, Validation, ValidationArgs } from '@vuelidate/core';
import AppDialog from 'components/AppDialog.vue';
import {
  BaseModel,
  Indexable,
  NestedKeyOf,
  NonNullableAll,
  ReadableModel,
} from 'models/base';
import { PagedModel } from 'models/paged';
import {
  ValidatableStoreSetup,
  ValidatableStoreUpdateOptions,
} from 'models/store';
import { useBackRoute } from 'shared/composables';
import { NotState } from 'shared/decorators';
import { useValidation } from 'shared/reactive';
import { computed, ref, type Ref } from 'vue';
import { RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';
import { ModelStore } from './model';

/**
 * A class for all stores that use models with validation rules
 */
export class ValidatableStore<
  M extends BaseModel = BaseModel,
  MList extends ReadableModel = ReadableModel,
  MPaged extends PagedModel<MPagedItems> = PagedModel<any>,
  Filter extends object = any, //
  // These types are inferred and typically aren't set by developers
  //
  MPagedItems extends ReadableModel = ReadableModel,
  StoreSetup extends ValidatableStoreSetup<
    M,
    MList,
    MPaged,
    Filter,
    MPagedItems
  > = ValidatableStoreSetup<M, MList, MPaged, Filter, MPagedItems>
> extends ModelStore<M, MList, MPaged, Filter, MPagedItems, StoreSetup> {
  private backRoute!: Ref<RouteLocationNamedRaw>;

  /**
   * Initializes the store
   */
  init() {
    super.init();
    this.backRoute = useBackRoute();
    this.modelRules = useValidation(this.storeSetup.modelRules, this.model);
  }

  /**
   * The validation rules for the model
   */
  modelRules!: Ref<Validation<ValidationArgs<M>, M>>;

  /**
   * Indicates if the model is currently validating
   */
  modelValidationsPending = computed(() => this.modelRules.value.$pending);

  /**
   * Indicates if the model passes the validation rules
   */
  modelValid = computed(() => !this.modelRules.value.$invalid);

  /**
   * Indicates if the model has been modified
   */
  modelModified = computed(() => this.modelRules.value.$anyDirty);

  /**
   * Gets the properties of the model that have been modified
   */
  modelModifiedProps = computed(() => {
    const modifiedProps = {} as Indexable;
    const modelKeys = Object.keys(this.model.value);

    modelKeys.forEach((key) => {
      const validationRule = this.modelRules.value[key] as BaseValidation<M>;

      if (validationRule?.$anyDirty) {
        modifiedProps[key] = validationRule.$model;
      }
    });

    return modifiedProps as NonNullableAll<M>;
  });

  /**
   * Indicates if canceling changes is disabled
   */
  cancelDisable = computed(() => this.modelValidationsPending.value);

  /**
   * Indicates if resetting the model is disabled
   */
  resetDisable = computed(
    () => this.modelValidationsPending.value || !this.modelModified.value
  );

  /**
   * Indicates if saving the model is disabled
   */
  saveDisable = computed(
    () =>
      this.modelValidationsPending.value ||
      !this.modelModified.value ||
      !this.modelValid.value
  );

  /**
   * Indicates if a validation error has disabled the save action
   */
  saveError = computed(() => !this.modelValid.value);

  /**
   * Updates the current model and takes a snapshot of the new model
   * @param model The new model
   * @param options The options to control what happens while updating the model
   */
  updateModel(
    model: Partial<M>,
    options?: ValidatableStoreUpdateOptions
  ): void {
    super.updateModel(model);

    if (options?.resetModelRules !== false) {
      this.resetModelRules();
    }
  }

  /**
   * Resets the validation rules for the model
   */
  resetModelRules() {
    this.snapshot();
    this.modelRules.value.$reset();
  }

  @NotState()
  protected isLeavingRouteField = ref(false);

  /**
   * Indicates if the user is navigating away from the current route. Note: This value is only available
   * for the duration of the current tick. On the next tick it is always reset to `false`.
   */
  isLeavingRoute = computed(() => this.isLeavingRouteField.value);

  /**
   * Prompts the user to confirm they want to leave the current page if there changes
   * @param to The route to navigate to if the user confirms or there are no changes
   * @returns `true` if the navigation can proceed; otherwise, `false`
   * @example
   * const deviceStore = useDeviceStore();
   * onBeforeRouteLeave((to) => deviceStore.confirmLeaveRoute(to));
   */
  confirmLeaveRoute(to: RouteLocationNormalized) {
    if (this.isLeavingRoute.value || !this.modelModified.value) {
      // Reset the flag back after the event queue is empty
      setTimeout(() => (this.isLeavingRouteField.value = false));
      return true;
    }

    const dialog = this.quasar.dialog({
      component: AppDialog,
      componentProps: {
        title: 'Confirm Leave',
        message: 'Are you sure you want to leave? There are unsaved changes.',
      },
    });

    dialog.onOk(() => {
      this.isLeavingRouteField.value = true;
      this.router.push(to);
    });

    return false;
  }

  /**
   * Resets a property back to its previous value and resets the corresponding validation rule.
   * @param path The path of the property to reset
   */
  resetModelProp(path: NestedKeyOf<M>) {
    const previousValue = Reflect.getPath(this.previousModel.value, path);
    Reflect.setPath(this.model.value, path, previousValue);

    // Nested rules are grouped under the first part of a path
    // Example: [Device Name Property Rule] => this.rules.device.$model.name
    const ruleKey = (path as string).split('.')[0];
    this.modelRules.value[ruleKey]?.$reset();
  }

  /**
   * Sets the current model to it's default state
   * @param value Optional default value
   */
  setDefault(value?: M) {
    super.setDefault(value);
    this.resetModelRules();
  }

  /**
   * Resets the current model back to its previous value
   */
  reset() {
    const doReset = () => {
      super.reset();
      this.resetModelRules();
    };

    if (this.modelModified.value) {
      const dialog = this.quasar.dialog({
        component: AppDialog,
        componentProps: {
          title: 'Confirm Reset',
          message: 'Are you sure you want to reset your changes?',
        },
      });

      dialog.onOk(doReset);
    } else {
      doReset();
    }
  }

  /**
   * Cancels the changes to the `model` and navigates to the parent route.
   * If the `model` has been modified, the user is prompted to cancel.
   */
  cancel() {
    const doCancel = () => {
      // Reset rules so we don't trigger a potential double-prompt leaving
      // the current page with changes
      this.resetModelRules();

      // Wait for the event queue to empty to give all properties a chance to reset
      // before navigation away
      setTimeout(() => {
        this.router.push(this.backRoute.value);
      });
    };

    if (this.modelModified.value) {
      const dialog = this.quasar.dialog({
        component: AppDialog,
        componentProps: {
          title: 'Confirm Cancel',
          message: 'Are you sure you want to cancel your changes?',
        },
      });

      dialog.onOk(doCancel);
    } else {
      doCancel();
    }
  }

  /**
   * Validates the current model with the current validation rules
   * @returns `true` if the model passes validation; otherwise, `false`
   */
  async validateModel() {
    // Vuelidate marks all validated properties as modified, which we don't want.
    //
    // This tracks the actual modified properties before validating then resets
    // the modified state of the properties that's weren't actually modified.
    // const actualModifiedProps = { ...this.modelModifiedProps.value };
    const isValid = await this.modelRules.value.$validate();

    // TODO: Revisit this because for some reason resetting fields that were never
    // modified affects the values of other fields

    // Reset the properties that weren't actually modified
    // Object.keys(this.model.value).forEach((key) => {
    //   if (!Object.hasOwn(actualModifiedProps, key)) {
    //     this.resetProp(key as NestedKeyOf<M>);
    //   }
    // });

    return isValid;
  }

  /**
   * A utility method that provides type-safety and intellisense for building
   * validation rules without the need to declare the generic type that matches
   * the model type of this store.
   * @param rules The validation rules
   * @returns The validation rules
   */
  protected buildModelRules(rules: ValidationArgs<M>) {
    return rules;
  }
}

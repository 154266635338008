import mitt, { Emitter, EventType } from 'mitt';
import { onUnmounted } from 'vue';

/**
 * Creates a proxy for the `mitt` `on` method that automatically calls `off` to remove
 * the event handler when the current component unmounts
 * @param emitter The target event emitter
 * @returns A proxy for the `mitt` `on` function that automatically cleans up after itself
 */
export function createAutoCleanupOnFunctionProxy<
  Events extends Record<EventType, unknown>
>(emitter: Emitter<Events>, onFunction: (...args: any) => void) {
  const onFunctionProxy = new Proxy(onFunction, {
    apply(target, thisArg, argArray) {
      // Automatically remove the added event handlers when the current component unmounts
      onUnmounted(() => {
        const eventName = argArray[0];
        const eventHandler = argArray[1];
        emitter.off(eventName, eventHandler);
      });

      // Call the original method to apply the event handler
      return Reflect.apply(target, thisArg, argArray);
    },
  });

  return onFunctionProxy;
}

/**
 * Creates a proxy of a `mitt` object that automatically cleans up added event handlers. All event handlers
 * that were added in the current component will be automatically removed when it unmounts.
 * @param emitter The emitter object to proxy
 * @returns A proxy event emitter that will automatically cleanup added event handlers
 */
export function createAutoCleanupEmitterProxy<
  Events extends Record<EventType, unknown>
>(emitter: Emitter<Events>) {
  const autoCleanupProxy = new Proxy(emitter, {
    get(target, propertyKey, receiver) {
      let propertyValue = Reflect.get(target, propertyKey, receiver);

      // Proxy an calls that add event handlers so they can be cleaned up automatically
      // when the current component unmounts
      if (propertyKey === 'on') {
        propertyValue = createAutoCleanupOnFunctionProxy(
          emitter,
          propertyValue
        );
      }

      return propertyValue;
    },
  });

  return autoCleanupProxy;
}

/**
 * Creates an event emitter instance. The event emitter automatically removes event handlers
 * added with `on` when the current component unmounts, so there is no need to manually call `off`
 * to remove the added event handlers.
 * @returns An event emitter that automatically cleans up after itself
 */
export function createEventEmitter<
  Events extends Record<EventType, unknown>
>() {
  const emitter = mitt<Events>();
  return createAutoCleanupEmitterProxy(emitter);
}

import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('layouts/Page.vue'),
    children: [
      {
        path: '',
        name: 'device-list',
        props: true,
        meta: {
          documentTitle: 'Device List',
          breadcrumbs: ['qa-today'],
          actionBar: {
            create: {
              label: 'New Device',
            },
          },
        },
        components: {
          default: () => import('pages/DeviceList.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
        },
      },
      {
        path: ':id',
        name: 'device-profile',
        props: true,
        meta: {
          documentTitle: 'Device Profile',
          breadcrumbLabel: 'Device Profile',
          breadcrumbs: ['qa-today'],
          actionBar: {
            edit: {
              label: 'Edit Device',
              to: { name: 'device-edit', params: { id: ':id' } },
            },
            create: {
              label: 'Configure New Test',
            },
          },
        },
        components: {
          default: () => import('pages/DeviceProfile.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
      {
        path: ':id/edit',
        name: 'device-edit',
        props: true,
        meta: {
          documentTitle: 'Edit Device',
          breadcrumbLabel: 'Edit Device',
          breadcrumbs: [
            'qa-today',
            { name: 'device-profile', params: { id: ':id' } },
          ],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceEdit.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
    ],
  },
] as RouteRecordRaw[];

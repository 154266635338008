import { createTestRunnerApi, TestSetApi } from 'api/test-runner';
import {
  TestSetListItemModel,
  TestSetModel,
  TestTemplateModel,
  NameModel
} from 'generated/TestRunnerService';
import type { ModelIdType } from 'models/base';
import { TestSetFilter, TestSetTestTemplate } from 'models/test-set';
import { TestConfigForm } from 'models/test-config-form';
import { defineClassStore, useSelectOptions } from 'shared/composables';
import { computed, ref } from 'vue';
import { ReadableApiStore } from './readable-api';

/**
 * Contains business logic for test sets
 */
export class TestSetStore extends ReadableApiStore<
  TestSetApi,
  TestSetModel,
  TestSetListItemModel,
  never,
  TestSetFilter
> {
  /**
   * Performs setup logic for the store
   * @returns The store setup
   */
  protected setup() {
    return this.buildSetup({
      api: createTestRunnerApi().testSet,
    });
  }

  /**
   * Gets a list of templates, with the given ids, from the current model
   * @param templateIds The list of ids for the templates
   * @returns The templates, with the given ids, from the current model
   */
  getTemplates(templateIds: ModelIdType[] | undefined) {
    return this.model.value.tests
      ?.flatMap((test) => test.testTemplates)
      .filter((template) =>
        templateIds?.includes(Number(template?.id))
      ) as TestTemplateModel[];
  }

  /**
   * Clears the list of selected test templates. If there is only a single template in the
   * selected test set, then it is automatically selected
   */
  clearSelectedTemplates() {
    this.selectedTemplateIds.value = [];

    // Automatically select the template if there's only one in the set
    if (this.selectedSetTestTemplates.value.length === 1) {
      this.selectedTemplateIds.value = [
        this.selectedSetTestTemplates.value[0].template?.id || 0,
      ];
    }
  }

  /**
   * Gets the list of templates in a test set along with their corresponding test
   * @param set The set to get the templates for
   * @returns A list of templates in the given test set along with their corresponding test
   */
  getTemplatesAndTests(set: TestSetListItemModel | undefined) {
    const selectedTestTemplates: TestSetTestTemplate[] = [];

    set?.tests?.forEach((test) => {
      test?.templates?.forEach((template) => {
        selectedTestTemplates.push({
          test,
          template,
        });
      });
    });

    return selectedTestTemplates;
  }

  /**
   * Indicates if a template is selected
   * @param template The template to check
   * @returns `true` if the template is selected; otherwise, `false`
   */
  isTemplateSelected(template: NameModel | undefined) {
    return this.selectedTemplateIds.value.includes(template?.id || 0);
  }

  /**
   * Gets the form for the current model
   * @returns The form for the current model
   */
  getForm() {
    return {
      testSetName: this.model.value.name,
      alias: this.model.value.name,
    } as Partial<TestConfigForm>;
  }

  selectedSetId = ref<ModelIdType>();
  selectedTemplateIds = ref<ModelIdType[]>([]);
  pagedModelOptions = useSelectOptions(this.listModels);

  /**
   * The currently selected test set
   */
  selectedSet = computed(() =>
    this.listModels.value?.find((set) => set.id === this.selectedSetId.value)
  );

  /**
   * All of the test templates in the selected test set
   */
  selectedSetTestTemplates = computed(() =>
    this.getTemplatesAndTests(this.selectedSet.value)
  );

  /**
   * Gets the selected templates in the selected test set
   */
  selectedSetTemplates = computed(() => {
    return this.selectedSetTestTemplates.value.filter((testTemplate) =>
      this.isTemplateSelected(testTemplate.template)
    );
  });

  /**
   * Gets all of test templates select options for the selected test set
   */
  selectedSetTemplateOptions = computed(() => {
    return this.selectedSetTestTemplates.value.map((testTemplate) => {
      return {
        label: testTemplate.test?.name === testTemplate.template?.name
          ? `${testTemplate.test?.name}`
          : `${testTemplate.test?.name} - ${testTemplate.template?.name}`,
        value: testTemplate.template?.id
      };
    });
  });

  okDisabled = computed(() => this.selectedTemplateIds.value.length === 0);
}

export const useTestSetStore = defineClassStore(
  'test-set',
  () => new TestSetStore()
);

import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('layouts/Page.vue'),
    children: [
      {
        path: 'reports',
        name: 'reports',
        meta: {
          documentTitle: 'Reports',
          navLabel: 'Reports',
        },
        component: () => import('pages/Reports.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

import { RouteRecordRaw } from 'vue-router';
import deviceRoutes from './routes-devices';
import deviceLogRoutes from './routes-device-logs';
import deviceTestConfigRoutes from './routes-device-test';

export default [
  {
    path: 'qa',
    name: 'qa',
    redirect: { name: 'qa-today' },
    meta: {
      navLabel: 'QA',
    },
    children: [
      {
        path: 'today',
        redirect: { name: 'qa-today' },
        children: [
          {
            path: '',
            // For some reason the Vue router throws a warning without a name here
            name: 'qa-today-default',
            component: () => import('layouts/Page.vue'),
            children: [
              {
                path: '',
                name: 'qa-today',
                meta: {
                  subNavLabel: "Today's QA",
                  breadcrumbLabel: "Today's QA",
                },
                props: true,
                components: {
                  default: () => import('pages/QAToday.vue'),
                },
              },
            ],
          },
          {
            path: 'devices',
            children: [
              ...deviceRoutes,
              ...deviceLogRoutes,
              ...deviceTestConfigRoutes,
            ],
          },
        ],
      },
      {
        path: 'compliance',
        name: 'compliance',
        meta: {
          documentTitle: 'Compliance',
          subNavLabel: 'Compliance',
        },
        component: () => import('pages/Compliance.vue'),
      },
      {
        path: 'calendar',
        name: 'calendar',
        meta: {
          documentTitle: 'Calendar',
          subNavLabel: 'Calendar',
        },
        component: () => import('pages/Calendar.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

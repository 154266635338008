import AppErrorDialog from 'components/AppErrorDialog.vue';
import { debounce, QVueGlobals, useQuasar } from 'quasar';
import { ComponentPublicInstance, onErrorCaptured } from 'vue';

/**
 * The global error handler for the application
 * @param err The error
 * @param instance The component instance where the error occurred
 * @param info Error information
 */
export function globalErrorHandler(
  err: unknown,
  instance: ComponentPublicInstance | null,
  info: string
): void {
  // TODO: Logs stuff
  console.error('GLOBAL APP ERROR', err, instance, info);
}

/**
 * Captures and display application errors
 */
export function captureErrors() {
  let error: any;
  // For some reason `useQuasar()` returns `undefined` when used in a debounced function.
  // This workaround fixes that problem.
  const quasar = useQuasar();
  // Debounce showing error message to prevent slamming the user with dialogs to close
  // if multiple errors are thrown rapidly in succession
  const showErrorDebounced = debounce(() => showError(error, quasar), 500);

  onErrorCaptured((err: any) => {
    error = err;
    showErrorDebounced();
  });
}

/**
 * Shows an error message
 * @param err The error information
 * @param quasar Optional Quasar instance to use
 */
export function showError(err: any, quasar?: QVueGlobals) {
  quasar = quasar || useQuasar();

  quasar.dialog({
    component: AppErrorDialog,
    componentProps: {
      error: err,
    },
  });
}

import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { resolveUnref, useTitle, watchDebounced } from '@vueuse/core';
import { Handler } from 'mitt';
import { ActionBarSyncSource } from 'models/action-bar';
import {
  BaseModel,
  Indexable,
  NestedKeyOf,
  NonNullableAll,
  UnwrapAll,
} from 'models/base';
import { Pagination } from 'models/pagination';
import { ModelQueryStringSync } from 'models/reactive';
import { useActionBarStore } from 'src/stores/action-bar';
import { BaseReadableApiStore } from 'src/stores/readable-api';
import { ValidatableStore } from 'stores/validatable';
import {
  computed,
  onUnmounted,
  ref,
  Ref,
  watch,
  watchEffect,
  WatchStopHandle,
} from 'vue';
import { LocationQuery, onBeforeRouteUpdate, useRoute } from 'vue-router';
import packageFile from '../../package.json';
import { useQueryString, useSetTimeout } from './composables';
import { isQueryParamType, isValueEmpty, parseValue } from './utility';

/**
 * Synchronizes the title of the current route with the document title.
 */
export function syncDocumentTitleWithRoute() {
  const documentTitle = useTitle();
  const route = useRoute();

  watch(
    () => route.name,
    () => {
      let routeTitle = packageFile.productName;

      route.matched.forEach((match) => {
        if (match.meta.documentTitle !== undefined) {
          routeTitle += ` - ${match.meta.documentTitle}`;
        }
      });

      documentTitle.value = routeTitle;
    },
    { immediate: true }
  );
}

/**
 * Uses the properties of a store to automatically update the state of the action bar
 * @param source The source to watch
 * @param overrides Optional overrides for the source
 * @returns A function that, when called, stops using the store with the action bar
 */
export function syncWithActionBar(
  source: ActionBarSyncSource,
  overrides?: ActionBarSyncSource
): WatchStopHandle {
  const actionBarStore = useActionBarStore();

  const sourceProxy = {
    ...source,
    ...overrides,
    ...{
      // The spread operator will kill reactivity of properties because we have to reference them
      // against the owning object. A computed is used here to ensure all properties are
      // reference through their owning object and to keep reactivity.
      createDisable: computed(() =>
        resolveUnref(overrides?.createDisable || source.createDisable)
      ),
      saveDisable: computed(() =>
        resolveUnref(overrides?.saveDisable || source.saveDisable)
      ),
      saveError: computed(() =>
        resolveUnref(overrides?.saveError || source.saveError)
      ),
      editDisable: computed(() =>
        resolveUnref(overrides?.editDisable || source.editDisable)
      ),
      cancelDisable: computed(() =>
        resolveUnref(overrides?.cancelDisable || source.cancelDisable)
      ),
      resetDisable: computed(() =>
        resolveUnref(overrides?.resetDisable || source.resetDisable)
      ),
      deleteDisable: computed(() =>
        resolveUnref(overrides?.deleteDisable || source.deleteDisable)
      ),
    },
  };

  if (sourceProxy.create) {
    actionBarStore.on('create', sourceProxy.create as Handler<void>);
  }

  if (sourceProxy.save) {
    actionBarStore.on('save', sourceProxy.save as Handler<void>);
  }

  if (sourceProxy.cancel) {
    actionBarStore.on('cancel', sourceProxy.cancel);
  }

  if (sourceProxy.edit) {
    actionBarStore.on('edit', sourceProxy.edit as Handler<void>);
  }

  if (sourceProxy.reset) {
    actionBarStore.on('reset', sourceProxy.reset);
  }

  if (sourceProxy.delete) {
    actionBarStore.on('delete', sourceProxy.delete as Handler<void>);
  }

  const offCreateDisable = watchEffect(
    () =>
      (actionBarStore.actionsByName.create.disable =
        sourceProxy.createDisable.value)
  );

  const offSaveDisable = watchEffect(
    () =>
      (actionBarStore.actionsByName.save.disable =
        sourceProxy.saveDisable.value)
  );

  const offSaveError = watchEffect(
    () =>
      (actionBarStore.actionsByName.save.error = sourceProxy.saveError.value)
  );

  const offEditDisable = watchEffect(
    () =>
      (actionBarStore.actionsByName.edit.disable =
        sourceProxy.editDisable.value)
  );
  const offCancelDisable = watchEffect(
    () =>
      (actionBarStore.actionsByName.cancel.disable =
        sourceProxy.cancelDisable.value)
  );
  const offResetDisable = watchEffect(
    () =>
      (actionBarStore.actionsByName.reset.disable =
        sourceProxy.resetDisable.value)
  );
  const offDeleteDisable = watchEffect(
    () =>
      (actionBarStore.actionsByName.delete.disable =
        sourceProxy.deleteDisable.value)
  );

  // Provide a way to manually stop to be consistent with the `watch` api. Event handlers
  // are automatically cleaned up, so developers typically don't need to call this.
  const stopHandle = () => {
    actionBarStore.off('create', source.create as Handler<void>);
    actionBarStore.off('save', source.save as Handler<void>);
    actionBarStore.off('edit', source.edit as Handler<void>);
    actionBarStore.off('cancel', source.cancel);
    actionBarStore.off('reset', source.reset);
    actionBarStore.off('delete', source.delete as Handler<void>);

    offCreateDisable();
    offSaveDisable();
    offSaveError();
    offEditDisable();
    offCancelDisable();
    offResetDisable();
    offDeleteDisable();
  };

  return stopHandle;
}

/**
 * Synchronizes changes between a reference and the query string
 * @param source The source to synchronize with the query string
 * @param onQueryStringChange Optional callback that is called when the query string changes.
 * This is typically used to refresh store data when the query string changes.
 * @returns A `ref` that always updates after the source
 */
export function syncWithQueryString(
  source: Ref,
  onQueryStringChange?: () => Promise<void>
) {
  let lock = false;
  const queryString = useQueryString();
  const resultUpdates = ref();

  const updateSource = (query: LocationQuery) => {
    const newSource: Indexable = {};

    Object.keys(query).forEach((key) => {
      if (Object.hasOwn(source.value, key)) {
        newSource[key] = parseValue(query[key], {
          isArray: Array.isArray(source.value[key]),
        });
      }
    });

    source.value = newSource;
    resultUpdates.value = newSource;
  };

  const updateQueryString = (newValue: any, oldValue?: any) => {
    // Use a different reference, with the spread operator, so the query string
    // value updates when assigned below. If the same reference is used, change
    // detection won't see it as a change.
    const newQueryString = { ...queryString.value };

    // Add modified values to the query string
    Object.keys(newValue).forEach((key) => {
      if (isValueEmpty(newValue[key]) || newValue[key] === false) {
        delete newQueryString[key];
      } else {
        newQueryString[key] = source.value[key];
      }
    });

    // Remove values from the query string that are no longer there
    if (oldValue) {
      Object.keys(oldValue).forEach((key) => {
        // The key is removed if it's no longer on the new value
        if (!Object.hasOwn(newValue, key)) {
          delete newQueryString[key];
        }
      });
    }

    queryString.value = newQueryString;
  };

  // Use the query string as the source of truth on initial load
  updateSource(queryString.value);
  // Update the query string with any default values on the source
  updateQueryString(source.value);

  watch(source, (newValue, oldValue) => {
    if (lock) {
      return;
    }

    lock = true;
    updateQueryString(newValue, oldValue);
    window.setTimeout(() => (lock = false));
  });

  onBeforeRouteUpdate(async (to) => {
    if (lock) {
      return;
    }

    lock = true;
    updateSource(to.query);
    window.setTimeout(() => (lock = false));
  });

  return resultUpdates;
}

/**
 * Sets the filter to the corresponding property values in the query string and updates
 * the query string with the modified filter values
 * @param store The store with the filter to synchronize with the query string
 * @returns A `ref` that always updates after the source
 */
export function syncFilterWithQueryString(
  store: UnwrapAll<BaseReadableApiStore<never>>
) {
  const filter = computed({
    get: () => store.filter,
    set: (value) => {
      Object.keys(store.filter).forEach((key) => {
        store.filter[key] = parseValue(value[key], {
          isArray: Array.isArray(store.filter[key]),
        });
      });
    },
  });

  return syncWithQueryString(filter);
}

const syncGetPageWithQueryStringStores: UnwrapAll<
  BaseReadableApiStore<unknown>
>[] = [];

/**
 * Indicates if the `getPage` method of a readable API store is synced with the query string
 * @param store The store to check
 * @returns `true` if the given store is synced with the query string; otherwise, `false`
 */
export function isGetPagedSyncedWithQueryString(
  store: UnwrapAll<BaseReadableApiStore<unknown>>
) {
  return syncGetPageWithQueryStringStores.indexOf(store) >= 0;
}

/**
 * Synchronizes the `getPage` method of a readable API store with the query string.
 * @param store The store to sync
 * @returns A `ref` that updates after the filter and pagination of the given store update
 */
export function syncGetPageWithQueryString(
  store: UnwrapAll<BaseReadableApiStore<unknown>>
) {
  const updates = ref();
  const filterUpdates = syncPaginationWithQueryString(store);
  const paginationUpdates = syncFilterWithQueryString(store);

  // Wait until both the filter and pagination update before loading. This fixes a prior
  // bug where reloading the grid could fire multiple times when it should only happen once.
  watchDebounced(
    [filterUpdates, paginationUpdates],
    () => {
      updates.value = {
        filter: filterUpdates.value,
        pagination: paginationUpdates.value,
      };

      store.getPage();
    },
    { debounce: 200 }
  );

  // Track the stores that have `getPage` synced with the query string
  // so other areas of the code can automatically detect if a store is
  // being synced. This helps prevent double-loading and also makes the life
  // of developers easier because they don't have to worry about double-loading
  // and things just work correctly for them.
  syncGetPageWithQueryStringStores.push(store);

  // Remove the store from the array used to track the instances that are synced
  // with the query string
  onUnmounted(() => {
    const index = syncGetPageWithQueryStringStores.indexOf(store);
    syncGetPageWithQueryStringStores.splice(index, 1);
  });

  return updates;
}

/**
 * Sets the pagination to the corresponding property values in the query string and updates
 * the query string with the modified pagination values
 * @param store The store with the pagination to synchronize with the query string
 * @returns A `ref` that always updates after the source
 */
export function syncPaginationWithQueryString(
  store: UnwrapAll<BaseReadableApiStore<never>>
) {
  const pagination = computed({
    get: () => {
      const pagination = store.pagination;
      const defaults = store.defaultPagination;

      // Don't put default values on the query string
      return {
        page: pagination.page !== defaults.page ? pagination.page : undefined,
        rowsPerPage:
          pagination.rowsPerPage !== defaults.rowsPerPage
            ? pagination.rowsPerPage
            : undefined,
        descending:
          pagination.descending !== defaults.descending
            ? pagination.descending
            : undefined,
        sortBy:
          pagination.sortBy !== defaults.sortBy ? pagination.sortBy : undefined,
      } as Pagination;
    },
    set: (value: Pagination) => {
      store.pagination = {
        page: value.page || store.defaultPagination.page,
        rowsPerPage: value.rowsPerPage || store.defaultPagination.rowsPerPage,
        descending: value.descending,
        sortBy: value.sortBy || store.defaultPagination.sortBy,
      };
    },
  });

  return syncWithQueryString(pagination);
}

/**
 * Set the model to the field values in the query string and updates the query string with
 * the modified properties of the model
 * @param store The store that contains the model to synchronize with the query string
 */
export function syncModelWithQueryString(
  store: UnwrapAll<ValidatableStore>
): ModelQueryStringSync {
  const debounce = 250;
  const queryString = useQueryString({ replaceHistory: true });
  const updateQueue: any[] = [];
  const modelUpdates = ref({} as Indexable);
  let lock = false;

  const watchQueryStringHandle = watch(
    queryString as any,
    async (newQueryValue: LocationQuery, oldQueryValue: LocationQuery) => {
      if (lock) {
        return;
      }

      lock = true;

      // Update model properties from the values on the query string
      Object.keys(newQueryValue).forEach((key: string) => {
        // Skip query string keys that not are defined on the model.
        // NOTE: This requires developers to set default model values for create screens or they won't be assigned
        if (!Object.hasOwn(store.model, key)) {
          return;
        }

        // Wait for the event queue to empty before setting each property. This gives
        // reactive properties a chance to update and dynamic model rules time to get setup
        // and correctly track the dirty state.
        //
        // For example. in the `DeviceStore`, the model rules are dynamic based on what `type`
        // of device is selected. The `setTimeout` gives the extended attribute validators time
        // to get setup and do dirty tracking after updating the `type`.
        const delayedUpdate = useSetTimeout(() => {
          // Attempt to use the correct target type of the model property
          store.model[key] = parseValue(newQueryValue[key], {
            isArray: Array.isArray(store.model[key]),
          });
        });

        updateQueue.push(delayedUpdate);
      });

      // Reset model properties that are removed from the query string
      Object.keys(oldQueryValue || {}).forEach((key) => {
        const wasQueryStringKeyRemoved = !Object.hasOwn(store.model, key);

        if (wasQueryStringKeyRemoved) {
          store.resetModelProp(key as NestedKeyOf<BaseModel>);
        }
      });

      await Promise.all(updateQueue);
      modelUpdates.value = store.modelModifiedProps;
      setTimeout(() => (lock = false), debounce + 10);
    },
    // Use "immediate" so any query string values on page load are captured
    { immediate: true }
  );

  // Automatically set modified properties on the query string
  const watchModelHandle = watchDebounced(
    () => store.modelModifiedProps,
    (newModelValue, oldModelValue) => {
      if (lock) {
        return;
      }

      lock = true;

      // Use a different reference, with the spread operator, so the query string
      // value updates when assigned below. If the same reference is used, change
      // detection won't see it as a change.
      const newQueryString = { ...queryString.value };

      // Add modified values to the query string
      Object.keys(newModelValue).forEach((key) => {
        if (!isQueryParamType(newModelValue[key])) {
          return;
        }

        if (!isValueEmpty(newModelValue[key])) {
          newQueryString[key] = newModelValue[key];
        }
      });

      // Remove values from the query string that are no longer modified
      Object.keys(oldModelValue || {}).forEach((key) => {
        const shouldRemove =
          !Object.hasOwn(newModelValue, key) ||
          isValueEmpty(newModelValue[key]);

        if (shouldRemove) {
          delete newQueryString[key];
        }
      });

      queryString.value = newQueryString;
      setTimeout(() => (lock = false));
    },
    { debounce }
  );

  return {
    modelUpdates,
    stop() {
      watchQueryStringHandle();
      watchModelHandle();
    },
  };
}

/**
 * Creates Vuelidate validation rules with application-wide defaults
 * @param rules The validation rules
 * @param model The model to validate
 * @returns The validation rules
 */
export function useValidation<T>(
  rules: Ref<ValidationArgs<T>> | ValidationArgs<T> | undefined,
  model: Ref<NonNullableAll<T>>
) {
  return useVuelidate(rules || ({} as ValidationArgs<T>), model as Ref<T>, {
    // Only validate when modified
    $lazy: true,

    // Use the model for dirty tracking so developers don't have to bind the UI to the model
    // through the rules
    // Example: `deviceStore.model.name` instead of `deviceStore.rules.name.$model`
    $autoDirty: true,
  });
}

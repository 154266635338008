import { Indexable } from 'models/base';
import { MetadataPropertyKey } from 'models/reflection';
import { isRef } from 'vue';

/**
 * Gets all of the immediate and inherited members of a class instance.
 * @param prototype The prototype
 * @mapMember Maps each member to a custom value. If a member is mapped to `undefined`,
 * then it is excluded from the result.
 * @returns All immediate and inherited members
 */
export function flattenMembers<T, M>(
  prototype: T,
  mapMember?: (key: MetadataPropertyKey, prototype: any, index?: number) => M
): Record<PropertyKey, M> {
  let members = {} as Indexable;

  // Don't include members from the Object prototype
  if (prototype === undefined || prototype === Object.prototype) {
    return members;
  }

  const basePrototype = Object.getPrototypeOf(prototype);
  members = flattenMembers<T, M>(basePrototype, mapMember);

  Object.getOwnPropertyNames(prototype)
    .filter((key) => key !== 'constructor')
    .forEach((key, index) => {
      let member;

      if (mapMember) {
        member = mapMember(key, prototype, index);
      } else {
        member = (prototype as Indexable)[key];
      }

      if (typeof member !== 'undefined') {
        members[key] = member;
      }
    });

  return members;
}

/**
 * Gets all of the immediate and inherited members of a class instance.
 * @param prototype The prototype
 * @returns All immediate and inherited members
 */
export function flattenDescriptors<T>(prototype: T) {
  return flattenMembers(prototype, (key, target) => {
    const descriptor = Object.getOwnPropertyDescriptor(target, key);
    return descriptor;
  });
}

/**
 * Sets a value with the given property path. Deep paths are supported.
 * @param target Object that contains the property on itself or in its prototype chain.
 * @param propertyPath The nested path to the property to set
 * @param value The new value
 * @param receiver The reference to use as the `this` value in the setter function,
 *        if `target[propertyKey]` is an accessor property.
 * @returns `true` if setting the value was successful; otherwise, false
 */
Reflect.setPath = function (target: any, propertyPath: string, value: any) {
  const pathParts = propertyPath?.split('.');
  let pathPart = pathParts[0];

  for (let i = 1; i < pathParts?.length; i++) {
    target = target[pathPart];
    pathPart = pathParts[i];
  }

  target[pathPart] = value;
  return true;
};

/**
 * Gets the value of a property with the given path
 * @param target Object that contains the property on itself or in its prototype chain
 * @param propertyPath The property path
 * @returns The value of the property with the given path
 */
Reflect.getPath = function (target: any, propertyPath: string) {
  let result = target;
  const pathParts = propertyPath?.split('.');

  pathParts?.forEach((pathPart) => (result = result[pathPart]));

  return result;
};

/**
 * Determines if an object is an async function
 * @param target The target object to check
 * @returns `true` if the object is an async function; otherwise, `false`
 */
Reflect.isAsyncFunction = function (target: any) {
  return target?.constructor?.name === 'AsyncFunction';
};

/**
 * Sets the property or of target, equivalent to `target[propertyKey] = value` when `receiver === target`.
 * This automatically detects if the property is a `Ref<an>`.
 * @param target Object that contains the property on itself or in its prototype chain
 * @param propertyKey Name of the property
 * @param value The new value
 * @param receiver The reference to use as the `this` value in the setter function,
 *        if `target[propertyKey]` is an accessor property
 * @returns `true` if setting the value was successful; otherwise, false
 */
Reflect.setRef = function (
  target: object,
  propertyKey: MetadataPropertyKey,
  value: any,
  receiver?: any
) {
  const property = Reflect.get(target, propertyKey, receiver);

  if (isRef(property)) {
    property.value = value;
    return true;
  } else {
    return Reflect.set(target, propertyKey, value, receiver);
  }
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Reflect {
    /**
     * Gets the value of a property with the given path
     * @param target Object that contains the property on itself or in its prototype chain
     * @param propertyPath The property path
     * @returns The value of the property with the given path
     */
    function getPath(target: any, propertyPath: string, receiver?: any): any;

    /**
     * Sets a value with the given property path. Deep paths are supported.
     * @param target Object that contains the property on itself or in its prototype chain.
     * @param propertyPath The nested path to the property to set
     * @param value The new value
     * @param receiver The reference to use as the `this` value in the setter function,
     *        if `target[propertyKey]` is an accessor property.
     * @returns `true` if setting the value was successful; otherwise, false
     */
    function setPath(
      target: any,
      propertyPath: string,
      value: any,
      receiver?: any
    ): boolean;

    /**
     * Determines if an object is an async function
     * @param target The target object to check
     * @returns `true` if the object is an async function; otherwise, `false`
     */
    function isAsyncFunction(target: any): boolean;

    /**
     * Sets the property or of target, equivalent to `target[propertyKey] = value` when `receiver === target`.
     * This automatically detects if the property is a `Ref<an>`.
     * @param target Object that contains the property on itself or in its prototype chain
     * @param propertyKey Name of the property
     * @param value The new value
     * @param receiver The reference to use as the `this` value in the setter function,
     *        if `target[propertyKey]` is an accessor property
     * @returns `true` if setting the value was successful; otherwise, false
     */
    function setRef(
      target: object,
      propertyKey: MetadataPropertyKey,
      value: any,
      receiver?: any
    ): boolean;
  }
}

import { ActionBarRouteConfig } from 'models/action-bar';
import { RouteRecordRaw } from 'vue-router';
import adminRoutes from './routes-admin';
import deviceManagementRoutes from './routes-device-management';
import qaRoutes from './routes-qa';
import reportsRoutes from './routes-reports';

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * The title of the document to display and also the history entry
     */
    documentTitle?: string;

    /**
     * The label to display for a main navigation route
     */
    navLabel?: string;

    /**
     * The label to display for a sub-navigation route
     */
    subNavLabel?: string;

    /**
     * The label to display for a breadcrumb
     */
    breadcrumbLabel?: string;

    /**
     * The list of route names for the breadcrumbs for this route
     */
    breadcrumbs?: RouteLocationRaw[];

    /**
     * Configuration for the action bar
     */
    actionBar?: ActionBarRouteConfig;
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/App.vue'),
    children: [
      {
        path: '',
        name: 'home',
        redirect: { name: 'qa-today' },
      },
      ...qaRoutes,
      ...deviceManagementRoutes,
      ...reportsRoutes,
      ...adminRoutes,
      {
        path: '',
        component: () => import('layouts/Page.vue'),
        children: [
          {
            path: 'status',
            meta: {
              documentTitle: 'Status',
            },
            component: () => import('pages/Status.vue'),
          },
        ],
      },
    ],
  },
  {
    // Always leave this as last one,
    // but you can also remove it
    path: '/:catchAll(.*)*',
    component: () => import('layouts/App.vue'),
    children: [
      {
        path: '',
        meta: {
          documentTitle: 'Not Found',
        },
        component: () => import('pages/ErrorNotFound.vue'),
      },
    ],
  },
];

export default routes;

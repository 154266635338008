import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'device-management',
    name: 'device-management',
    redirect: { name: 'device-management-default' },
    meta: {
      documentTitle: 'Device Management',
      navLabel: 'Device Management',
      breadcrumbLabel: 'Device Management',
    },
    component: () => import('layouts/Page.vue'),
    children: [
      {
        path: '',
        name: 'device-management-default',
        meta: {
          actionBar: {
            create: {
              label: 'New Device',
              to: { name: 'device-management-create' },
            },
          },
        },
        components: {
          default: () => import('pages/DeviceManagement.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
        },
      },
      {
        path: 'create',
        name: 'device-management-create',
        props: true,
        meta: {
          documentTitle: 'New Device',
          breadcrumbLabel: 'New Device',
          breadcrumbs: ['device-management'],
          actionBar: {
            save: {},
            cancel: {},
            reset: {},
          },
        },
        components: {
          default: () => import('pages/DeviceCreate.vue'),
          actionBar: () => import('components/AppActionBar.vue'),
          breadcrumbs: () => import('components/AppBreadcrumbs.vue'),
        },
      },
    ],
  },
] as RouteRecordRaw[];
